import { PoleState } from './pole-form.component';
import { Ampere, EcComponent } from '@ec-workspace/ec-library';
import { PoleType } from '../../../ec-mdb-manager/ec-mdb-manager.model';

export class PoleFormAdaptor {
  public static convertToPoleState(
    e: EcComponent,
    type: PoleType,
    poleSize: Ampere = 0,
    voltage = 0
  ): PoleState {
    e.voltage = voltage;
    return {
      current: e.current,
      metaData: undefined,
      name: e.name,
      power: e.power,
      type,
      component: e
    };
  }
}
