<div nxLayout="grid nopadding" class="dev-tools-box" xmlns:svg="">

  <div nxRow>
    <div nxCol="9,12,12,9">
      <p i18n  style="font-size:16px" >Click on ✏️ icon for edit,  Click on ➕ icon for add or delete children, Click on 🔄 for change equipment</p>
    </div>
  </div>

  <div nxRow>
    <div nxCol="9,12,12,9">
      <nx-badge class="normal" i18n>New</nx-badge>
      <nx-badge class="info" i18n>Need to config</nx-badge>
      <nx-badge class="success" i18n>Existing</nx-badge>
      <nx-badge class="warning" i18n>Need to change</nx-badge>
      <nx-badge class="danger" i18n>Error</nx-badge>

    </div>
  </div>

  <div nxRow>
    <div nxCol="9,12,12,9" class="topButtons">
<!--      <button nxButton="primary small" (click)="saveConfigFile()"><nx-icon name="download"></nx-icon> Save configuration</button>-->
<!--      <button nxButton="primary small" (click)="clear()"><b>New/Clear</b></button>-->
      <button nxButton="primary small" (click)="zoomOut()" ><nx-icon name="minus"></nx-icon></button>
      <button nxButton="primary small" (click)="zoomIn()" ><nx-icon name="plus"></nx-icon></button>
      <button nxButton="primary small" (click)="resetZoom()" i18n>Reset Zoom</button>
      <button nxButton="primary small" (click)="center$.next(true)" i18n>Center</button>
      <button nxButton="primary small" (click)="zoomToFit$.next(true)" i18n>Zoom to fit</button>
<!--      <button nxButton="primary small" (click)="saveImage()">Save SVG</button>-->
<!--      <button nxButton="primary small" (click)="saveImage(true)">Save PNG</button>-->
<!--      <nx-checkbox [checked]="editorService.renderServiceActivators" (checkedChange)="renderServiceActivatorsClicked($event)"-->
<!--      >Show Service Activators</nx-checkbox-->
<!--      >-->
<!--      <button nxButton="primary small" (click)="addNewPage()"><nx-icon name="file"></nx-icon> Add new Page</button>-->
    </div>
  </div>

  <div nxRow>
    <div nxCol="9,12,12,9">
      <div class="configGraphContainer" #configGraphContainer>
        <ngx-graph
          *ngIf="graphModel"
          class="chart-container"
          [links]="graphModel.edges"
          [nodes]="graphModel.nodes"
          [curve]="curve"
          [clusters]="graphModel.clusters"
          [enableZoom]="false"
          [zoomLevel]="zoomLevel"
          [view]="view"
          (zoomChange)="zoomChange($event)"
          [zoomSpeed]="0.05"
          [draggingEnabled]="false"
          [center$]="center$"
          [zoomToFit$]="zoomToFit$"
          [layout]="layout"
          [showMiniMap]="true"
        >
          >
          <ng-template #defsTemplate>
            <svg:marker id="arrow" viewBox="0 -5 10 10" refX="8" refY="0" markerWidth="4" markerHeight="4" orient="auto">
              <svg:path d="M0,-5L10,0L0,5" class="arrow-head" />
            </svg:marker>
          </ng-template>

          <ng-template #nodeTemplate let-node>
            <svg:g
              class="node"
              [ngClass]="node.data.class"
            >
              <svg:rect rx="5" ry="5" [attr.width]="node.dimension.width + 10" [attr.height]="node.dimension.height" [attr.fill]="node.data.color" >
                <animate *ngIf="node.data.class.isNotCompletedState" attributeName="opacity" dur="1.5s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
              </svg:rect>
              <svg:text alignment-baseline="central" [attr.x]="10" [attr.y]="1">
                <tspan x="10" dy="1.2em" >
                  {{ node.label }}
                  <ng-container *ngFor="let action of node.data.actions" >
                    <tspan dx="0.5em" (click)="action.onClick(node)">{{action.icon}}</tspan>
                  </ng-container>
                </tspan>
                <tspan x="10" dy="1.2em" *ngFor="let text of node.data.texts">{{text}}</tspan>
              </svg:text>
            </svg:g>
          </ng-template>

          <ng-template #linkTemplate let-link>
            <svg:g class="edge" [ngClass]="link.data.class">
<!--              <svg:path class="line" stroke-width="2" marker-end="url(#arrow)"></svg:path>-->
              <svg:path class="line" stroke-width="2" ></svg:path>
              <svg:text
                class="edge-label"
                text-anchor="middle"
                [nxTooltip]="link.data.tooltip"
              >
                <textPath
                  class="text-path"
                  [attr.href]="'#' + link.id"
                  [attr.xlink:href]="'#' + link.id"
                  [style.dominant-baseline]="link.dominantBaseline"
                  [attr.startOffset]="'50%'"
                >
                  <ng-container *ngIf="!link.data.canConfig">
                    {{ link.label }}
                  </ng-container>
                  <ng-container *ngIf="link.data.canConfig">
                    <ng-container *ngFor="let action of link.data.actions" >
                      <tspan (click)="action.onClick(link)">{{action.icon}}{{ link.label }}</tspan>
                    </ng-container>
                  </ng-container>

                </textPath>
              </svg:text>
            </svg:g>

          </ng-template>

          <ng-template #clusterTemplate let-cluster>
            <svg:g class="node cluster" [ngClass]="cluster.data.class">
              <svg:rect
                rx="5"
                ry="5"
                [attr.width]="cluster.dimension.width"
                [attr.height]="cluster.dimension.height"
                [attr.fill]="cluster.data.color"
              >
                <animate *ngIf="cluster.data.class.isNotCompletedState" attributeName="opacity" dur="1.5s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
              </svg:rect>
              <svg:text alignment-baseline="central" [attr.x]="20" [attr.y]="20">
                {{ cluster.label }}
                <ng-container *ngFor="let action of cluster.data.actions" >
                  <tspan dx="0.5em" (click)="action.onClick(cluster)">{{action.icon}}</tspan>
                </ng-container>
                <tspan x="20" dy="1.2em" *ngFor="let text of cluster.data.texts">
                  {{text}}
                </tspan>
              </svg:text>
            </svg:g>
          </ng-template>
        </ngx-graph>
      </div>
    </div>
  </div>

</div>
