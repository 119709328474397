import { DoublePole } from '../entities/double-pole';
import { Wire } from './wire';

export class Line extends Wire {
  get isExisting(): boolean {
    return true;
  }

  set isExisting(value: boolean) {
    this._isExisting = value;
  }

  get canConfig(): boolean {
    if (this._isLock) {
      return false;
    }
    if (this.output instanceof DoublePole && this.output.container) {
      return false;
    }
    return true;
  }

  get type() {
    return 'Wire';
  }
}
