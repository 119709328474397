import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TalyCoreModule } from '@allianz/taly-core';
import { CcValidationErrorsModule } from '@allianz/common-building-block-components';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { EcNgxGraphComponent } from './ec-ngx-graph.component';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NxTooltipModule } from '@aposin/ng-aquila/tooltip';
import { NxMessageModule } from '@aposin/ng-aquila/message';
import { NxBadgeModule } from '@aposin/ng-aquila/badge';
import { TooltipDirective } from '../shared/3rd-party/ngx-tooltip/ngx-tooltip.directive';
import { NxContextMenuModule } from '@aposin/ng-aquila/context-menu';
import { ChangeEquipmentFormModule } from '../shared/components/change-equipment-form/change-equipment-form.module';
import { EquipmentFormModule } from '../shared/components/equipment-form/equipment-form.module';

@NgModule({
  declarations: [EcNgxGraphComponent, TooltipDirective],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TalyCoreModule,
    CcValidationErrorsModule,
    NxHeadlineModule,
    NxGridModule,
    NxFormfieldModule,
    NxInputModule,
    NgxGraphModule,
    NxButtonModule,
    NxIconModule,
    NxTooltipModule,
    NxMessageModule,
    NxBadgeModule,
    NxContextMenuModule,
    ChangeEquipmentFormModule,
    EquipmentFormModule
  ],
  exports: [EcNgxGraphComponent]
})
export class EcNgxGraphModule {}
