<!--<nx-dynamic-table [nxData]="priceSummaryTable" [nxDisplayedColumns]='displayedColumnsComponent'></nx-dynamic-table>-->

<nx-swipebar label="Scroll to see more">
  <table nxTable condensed nxSort (sortChange)="sortTable($event)">
    <thead>
    <tr nxTableRow>
      <th nxHeaderCell [nxSortHeaderCell]="columnHeader.key" *ngFor="let columnHeader of displayedColumnsComponent"> {{ columnHeader.title }}</th>
    </tr>
    </thead>
    <tbody>
    <tr nxTableRow *ngFor="let item of priceSummaryTable; index as i">
      <td nxTableCell >{{i + 1}}</td>
      <td nxTableCell>{{item.description}}</td>
      <td nxTableCell>{{item.type}}</td>
      <td nxTableCell>{{item.productDescription}}</td>
      <td nxTableCell>{{item.quantity}}</td>
      <td nxTableCell>{{item.unitPrice | currency: '฿' }}</td>
      <td nxTableCell>{{item.amount | currency: '฿'}}</td>
    </tr>
    </tbody>
  </table>
</nx-swipebar>

<div class="total">
  <h1 i18n> Total: {{total | currency: ' '  }} Baht</h1>
</div>

