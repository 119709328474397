<div nxRow="">
  <div nxCol="12,12,12,12,6" class="nx-margin-bottom-s">
    <h4 nxHeadline="subsection-xsmall" class="nx-margin-bottom-s" i18n>
      Summary
    </h4>

    <nx-data-display i18n-label label="Total Price" orientation="horizontal">
      {{ this.getState().sumPrice | number : '1.2-2' }} THB
    </nx-data-display>

  </div>
</div>

<div class="scroll-container">
  <table nxTable condensed nxExpandableTable #expandableTable="nxExpandableTable" class="nx-margin-bottom-xs">
    <thead>
    <tr nxTableRow>
      <th nxHeaderCell i18n>Name</th>
      <th nxHeaderCell i18n>Parent</th>
      <th nxHeaderCell i18n>Category</th>
      <th nxHeaderCell i18n>Length / Amount</th>
      <th nxHeaderCell i18n>Price (THB)</th>
      <th nxHeaderCell></th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let control of formArray.controls; index as i">
      <tr
        nxTableRow

        [formGroup]="getFormGroup(i)"
      >
<!--        <td nxTableCell class="nx-table-cell&#45;&#45;toggle">-->
<!--          <nx-toggle-button-->
<!--            [target]="expandableRow"-->
<!--            ariaLabel="Toggle row"-->
<!--          ></nx-toggle-button>-->
<!--        </td>-->
        <td nxTableCell>
          {{ control.value['name'] }}
        </td>
        <td nxTableCell>
          {{ control.value['parent'] }}
        </td>
        <td nxTableCell>
          {{ control.value['category'] }}
        </td>
        <td nxTableCell>
          <ng-container *ngIf="control.value['category'] === 'Equipment'">
            {{ control.value['amount'] }}
          </ng-container>
          <ng-container *ngIf="control.value['category'] === 'Wire'">
<!--            <nx-formfield>-->
<!--              <input nxInput formControlName="amount" type="number" [min]="1"/>-->
<!--              <span nxFormfieldSuffix>m</span>-->
<!--            </nx-formfield>-->
            <nx-number-stepper
              [nxStep]="1"
              [nxMin]="1"
              [nxMax]="1000"
              formControlName="amount"
              nxResize="true"
              leadingZero="false"
              inputAriaLabel="Amount"
            >
              <nx-number-stepper-suffix>m</nx-number-stepper-suffix>
            </nx-number-stepper>
          </ng-container>
        </td>
        <td nxTableCell style="text-align: center">
          {{ this.getState().orders?.[i]?.['price'] | number : '1.2-2' }}
        </td>

      </tr>

      <tr nxExpandableTableRow #expandableRow>
        <td nxExpandableTableCell></td>
        <td nxExpandableTableCell>
          {{ control.value['description'] }}
        </td>
        <td nxExpandableTableCell colspan="3"> </td>
      </tr>

    </ng-container>

    </tbody>
  </table>
</div>

<!--<button nxPlainButton="" (click)="addRow()">-->
<!--  <nx-icon name="plus" class="nx-margin-right-2xs"></nx-icon>-->
<!--  Add row-->
<!--</button>-->
