/**
 * RCD
 */
import { Wire } from '../wires/wire';
import { Line } from '../wires/line';
import { Neutral } from '../wires/neutral';
import { ElectricalContainer } from './electrical-container';

export class ResidualCurrentDevice extends ElectricalContainer {
  _name = 'RCD';

  get type() {
    return 'RCD';
  }

  protected getWireForParent(): Wire[] {
    return [new Line(), new Neutral()];
  }
}
