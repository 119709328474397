import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TalyCoreModule } from '@allianz/taly-core';
import { CcValidationErrorsModule } from '@allianz/common-building-block-components';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { EcNewEquipmentConfigureComponent } from './ec-new-equipment-configure.component';

@NgModule({
  declarations: [EcNewEquipmentConfigureComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TalyCoreModule,
    CcValidationErrorsModule,
    NxHeadlineModule,
    NxGridModule,
    NxFormfieldModule,
    NxInputModule
  ],
  exports: [EcNewEquipmentConfigureComponent]
})
export class EcNewEquipmentConfigureModule {}
