import { ElectricalEquipment } from './electrical-equipment';
import { Ampere, Watt } from '../core/unit.model';

export class Lighting extends ElectricalEquipment {
  maximumChildren = 10;

  get current(): Ampere {
    return this.power / this.voltage;
  }

  get power(): Watt {
    this._power = this.children.reduce((acc, child) => acc + child.power, 0);
    return this._power;
  }

  addDefaultChild() {}

  getAvailableConfig() {
    return null;
  }
}
