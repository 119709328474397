import { NgModule } from '@angular/core';
import { TalyCoreModule } from '@allianz/taly-core';
import { processPluginOptions } from '@allianz/taly-core/runtime-utils';

import { envManager } from '../environment-manager';


import { ReduxDevtoolPluginModule } from "ec-bb";


@NgModule({
  imports: [
    TalyCoreModule

  ,
    ReduxDevtoolPluginModule.forRoot(
      
    )
  

  ]
})
export class TalyPluginsModule { }
