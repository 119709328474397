import { FlatJson } from './ecComponent';
import { Ampere } from '../core/unit.model';
import { Wire } from '../wires/wire';
import { GroundWire } from '../wires/ground-wire';
import { ElectricalContainer } from './electrical-container';
import {
  PriceSummary,
  Product
} from '../../../../ec-bb/src/lib/ec-price-summary/ec-price-summary.model';
import { Clamp } from './clamp';
import { GroundRod } from './ground-rod';

export class Ground extends ElectricalContainer {
  get isCompletedState() {
    return true;
  }

  get isError() {
    return false;
  }

  getCurrent(): Ampere {
    return 0;
  }

  public addWire(wire: Wire) {
    this.wires.push(wire);
    if (this.parent) {
      wire.setComponents(this.parent, this);
      wire.isExisting = this.isExisting;
      console.warn(wire.isExisting);
    }
  }

  getWireForParent(): Wire[] {
    return [new GroundWire()];
  }

  getFlatJson(): FlatJson {
    const json: any = super.getFlatJson();
    json.texts = [];
    json.tags.push(...['ground', 'verySmall', 'partner']);
    return json;
  }

  get canConfig() {
    return false;
  }

  findProduct(products: Product[]): Product | undefined {
    const targetProducts = ['GroundRod', 'Clamp'];
    for (const product of products) {
      if (targetProducts.includes(product.type)) {
        return product;
      }
    }
    return undefined;
  }

  getPriceSummary(products: Product[]): PriceSummary[] {
    const childWires = [new Clamp(), new GroundRod()];
    childWires.forEach((w) => {
      w.description = this.name;
    });
    return childWires.flatMap((w) => w.getPriceSummary(products));
  }
}
