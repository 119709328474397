import { Meter } from '../../entities/meter';
import { ElectricalDirector } from './electrical-director';
import { Pole } from '../../entities/pole';

export abstract class EvChargerInstall extends ElectricalDirector {
  isSkipConfigExistingDevice = false;

  initialForConfigExistingDevice() {}

  initialForConfigNewDeviceStep1() {
    this.traversal.getAllComponentsArray().forEach((c) => {
      if (c instanceof Pole) {
        if (c.current === 0) {
          c.canAdd = true;
        }
      }
    });
    this.traversal.recursive();
    console.log(this.traversal.root);
  }

  testing() {
    console.log('testing add ev');
    this.addEvChargerByName(`EvCharger 1`, 3400);
    this.addEvChargerByName(`EvCharger 2`, 7400);
    this.addEvChargerByName(`EvCharger 3`, 7400);
    this.removeEvCharger(`EvCharger 2`);
    this.addEvChargerByName(`EvCharger 4`, 7400);
  }

  testingCompleteAllExising() {
    console.log('testingCompleteAllExising');
    this.traversal.findChildren(this.traversal.root, (c) => {
      console.log(c.className);
      if (c.className === 'Meter') {
        c.currentSize = 50;
      }
      if (c.className === 'Rcbo2Pole') {
        c.currentSize = 50;
      }
    });
  }
}
