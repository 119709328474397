import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TalyCoreModule } from '@allianz/taly-core';
import { CcValidationErrorsModule } from '@allianz/common-building-block-components';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { EcMdbManagerComponent } from './ec-mdb-manager.component';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NxTableModule } from '@aposin/ng-aquila/table';
import { NxDatefieldModule } from '@aposin/ng-aquila/datefield';
import { NxDropdownModule } from '@aposin/ng-aquila/dropdown';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import { NxCopytextModule } from '@aposin/ng-aquila/copytext';
import { PoleFormModule } from '../shared/components/pole-form/pole-form.module';
import { NxBadgeModule } from '@aposin/ng-aquila/badge';
import { NxErrorModule } from '@aposin/ng-aquila/base';
import { NxDataDisplayModule } from '@aposin/ng-aquila/data-display';
import { NxMessageModule } from '@aposin/ng-aquila/message';
import { NxDynamicTableModule } from '@aposin/ng-aquila/dynamic-table';

@NgModule({
  declarations: [EcMdbManagerComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TalyCoreModule,
    CcValidationErrorsModule,
    NxHeadlineModule,
    NxGridModule,
    NxFormfieldModule,
    NxInputModule,
    NxIconModule,
    NxTableModule,
    NxDatefieldModule,
    NxDropdownModule,
    NxButtonModule,
    NxModalModule,
    PoleFormModule,
    NxCopytextModule,
    NxBadgeModule,
    NxErrorModule,
    NxDataDisplayModule,
    NxMessageModule,
    NxDynamicTableModule
  ],
  exports: [EcMdbManagerComponent]
})
export class EcMdbManagerModule {}
