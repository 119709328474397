import { BoxContainer, DynamicClass, EcComponent, FlatJson, Wire } from '@ec-workspace/ec-library';
import { BehaviorSubject } from 'rxjs';

export interface ComponentsState {
  recursiveJson: Record<string, EcComponent>;
  components: Record<string, FlatJson>;
  wires: Record<string, FlatJson>;
  boxContainers: Record<string, FlatJson>;
}

export class ElectricalTraversal {
  components: EcComponent[] = [];
  wires: Wire[] = [];
  boxContainers: BoxContainer[] = [];

  componentsObject: Record<string, EcComponent> = {};
  wiresObject: Record<string, Wire> = {};
  boxContainersObject: Record<string, BoxContainer> = {};

  allComponentsObject: Record<string, EcComponent | BoxContainer | Wire> = {};

  count = 0;

  componentsState$ = new BehaviorSubject<ComponentsState>({
    recursiveJson: {},
    boxContainers: {},
    components: {},
    wires: {}
  });

  constructor(protected _root: EcComponent) {}

  getAllComponentsArray(): (EcComponent | Wire | BoxContainer)[] {
    return Object.values(this.allComponentsObject);
  }

  get root() {
    return this._root;
  }

  set root(root) {
    this._root = root;
  }

  display() {
    console.log('Box');
    console.table(this.boxContainers);
    console.log('Wire');
    console.table(this.wires.map(this.wiresDisplayMapping));
    console.log('Component');
    console.table(this.components.map(this.componentDisplayMapping));
    console.log('JSON');
    console.log(this._root.getJsonData());
    console.log('Instance Root');
    console.log(this._root);
  }

  recursive() {
    this.reset();
    this._recursive(this._root);
    console.log('root', this._root);
    console.log('box', this.boxContainers);
    this.componentsState$.next({
      recursiveJson: this.root.getJsonData(),
      components: this.convertToObject(this.components as any),
      wires: this.convertToObject(this.wires as any),
      boxContainers: this.convertToObject(this.boxContainers as any)
    });
  }

  convertToObject(list: EcComponent[]): Record<string, FlatJson> {
    const object: Record<string, FlatJson> = {};
    list.forEach((i) => {
      object[i.id] = i.getFlatJson();
    });
    return object;
  }

  reset() {
    this.components = [];
    this.boxContainers = [];
    this.wires = [];
    this.allComponentsObject = {};
  }

  findByName(name: string, component = this._root): EcComponent | null {
    if (name === component.name) {
      return component;
    }
    for (const child of component.getChildren()) {
      const result = this.findByName(name, child);
      if (result) {
        return result;
      }
    }
    return null;
  }

  private _recursive(component: EcComponent) {
    this.components.push(component);
    this.allComponentsObject[component.id] = component;

    if (!('getWires' in component)) {
      console.warn(component);
    }

    component.getWires().forEach((w) => {
      this.allComponentsObject[w.id] = w;
      this.wires.push(w);
    });

    const box = component.container;
    if (box && !this.boxContainers.some((b) => b.id === box.id)) {
      this.boxContainers.push(box);
      box.children.forEach((c) => {
        this._recursive(c);
      });
      box.getWires().forEach((w) => {
        if (w) {
          this.allComponentsObject[w.id] = w;
          this.wires.push(w);
        }
      });
      this.allComponentsObject[box.id] = box;
    }

    for (const child of component.getChildren()) {
      this._recursive(child);
    }
  }

  private _recursiveWithOutBox(component: EcComponent) {
    this.components.push(component);
    for (const child of component.getChildren()) {
      this._recursiveWithOutBox(child);
    }
  }

  findUp(component: EcComponent | null, callback: (component: EcComponent) => void) {
    if (!component?.getParent()) {
      return;
    }
    callback(component);
    this.findUp(component?.getParent(), callback);
  }

  findChildren(component: EcComponent | null, callback: (component: EcComponent) => void) {
    if (!component) {
      return;
    }
    callback(component);
    if (component?.getChildren().length <= 0) {
      return;
    }
    component?.getChildren().forEach((c) => {
      this.findChildren(c, callback);
    });
  }

  componentDisplayMapping(component: EcComponent) {
    return {
      name: component.name,
      description: component.description,
      type: component.type,
      current: component.current,
      voltage: component.voltage,
      power: component.power,
      wires: component.getWires(),
      currentSize: component.currentSize,
      isOverCurrent: component.isOverCurrent,
      isCompletedState: component.isCompletedState,
      isExisting: component.isExisting,
      isError: component.isError,
      parent: component.getParent()?.name,
      container: component.container?.name
    };
  }

  wiresDisplayMapping(w: Wire) {
    return {
      name: w.name,
      description: w.description,
      type: w.type,
      current: w.current,
      currentSize: w.currentSize,
      input: w.input?.name,
      output: w.output?.name,
      isExisting: w.isExisting,
      isOverCurrent: w.isOverCurrent
    };
  }

  restoreState(componentsState: ComponentsState) {
    const root = this.recursiveRestore(componentsState.recursiveJson);
    console.log(`root`, root);
    this._root = root;
    this._recursiveWithOutBox(root);
    this.componentsState$.next({
      ...componentsState,
      recursiveJson: this.root.getJsonData()
    });
    this.display();
  }

  recursiveRestore(componentJson: Record<string, any>) {
    console.log(componentJson);

    const componentInstance: any = new DynamicClass(componentJson.className, componentJson);

    console.log(componentInstance);

    const childrenInstance: EcComponent[] = componentJson.children
      .map((c: any) => {
        return this.recursiveRestore(c);
      })
      .filter(Boolean);
    childrenInstance?.forEach((c) => {
      componentInstance.add(c);
    });

    const wireInstance: Wire[] = componentInstance?.getWires()?.map((w: any) => {
      return new DynamicClass(w.className, w);
    });
    wireInstance?.forEach((w) => {
      componentInstance.addWire(w);
    });
    const containerJson = componentJson.container;
    if (containerJson) {
      componentInstance.container = containerJson;
    }

    return componentInstance;
  }

  createClassDynamicly(className: string, instanceparameters: unknown) {
    // @ts-ignore
    console.log(x);
  }

  getNeedToBeConfigured() {
    return this.components.filter((c) => !c.isCompletedState);
  }
}
