import { NdbxIconModule } from '@allianz/ngx-ndbx/icon';
import { UpgradeIe11Module } from '@allianz/taly-common/ui';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NdbxSharedModule } from './ndbx-shared.module';
import { PfeIntegrationModule } from './pfe-integration.module';
import { TalyIntegrationModule } from './taly-integration.module';


@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    AppRoutingModule,
    UpgradeIe11Module,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NdbxSharedModule,
    PfeIntegrationModule,
    TalyIntegrationModule,
    NdbxIconModule
  ],
  bootstrap: [AppComponent],
  providers: []
})
export class AppModule {
 
}



