// Please remove unused imports according to the Plugin's requirement
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReduxDevtool } from './redux-devtool';
import { isDevMode } from '@angular/core';

@NgModule({ providers: [ReduxDevtool] })
export class ReduxDevtoolPluginModule {
  constructor(reduxDevtool: ReduxDevtool) {
    const isDev = false;
    console.log(`isDev ${isDev}`);
    if (isDev) {
      reduxDevtool.init();
    }
  }
  static forRoot(): ModuleWithProviders<ReduxDevtoolPluginModule> {
    return {
      ngModule: ReduxDevtoolPluginModule
    };
  }
}
