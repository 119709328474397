import { EcComponent } from './ecComponent';
import { AvailableConfigType } from './base-component';
import { Wire } from '../wires/wire';

export class GroundRod extends EcComponent {
  _name = 'Ground Rod';

  getAvailableConfig(): AvailableConfigType | null {
    return null;
  }

  getAvailableTypeOptions(): AvailableConfigType | null {
    return null;
  }

  protected getWireForParent(): Wire[] {
    return [];
  }
}
