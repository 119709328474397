import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  AbstractBuildingBlock,
  applyValidationConfig,
  createBuildingBlockProvider,
  ValidationConfig,
  ValidationConfigItem
} from '@allianz/taly-core';
import { autoFormBindingFactory, FormBindingReturnValue } from '@allianz/taly-acl/form-support';
import { EcSelectorResources, EcSelectorState } from './ec-selector.model';

import { LocalizeFn } from '@angular/localize/init';

declare let $localize: LocalizeFn;

@UntilDestroy()
@Component({
  selector: 'bb-ec-selector',
  templateUrl: 'ec-selector.component.html',
  styleUrls: ['ec-selector.component.scss'],
  providers: [createBuildingBlockProvider(EcSelectorComponent)]
})
export class EcSelectorComponent
  extends AbstractBuildingBlock<EcSelectorState, EcSelectorResources>
  implements OnInit
{
  aclFormBinding?: FormBindingReturnValue;

  validationMap?: Map<string, ValidationConfig[]>;

  formBuilder = new FormBuilder();

  form = this.formBuilder.group({
    data: ['', Validators.required]
  });

  resourceBB: EcSelectorResources = {
    title: '',
    options: []
  };

  cardArray;
  cardChecked: boolean[] = [];

  constructor(injector: Injector, private cdr: ChangeDetectorRef) {
    super(injector);
  }

  override ngOnInit(): void {
    this.setupFormChangesSubscriptions();
    this.setupAclBinding();
  }

  private setupAclBinding(): void {
    this.aclFormBinding = autoFormBindingFactory()(this.acl, this.form);
    this.aclFormBinding.stream$.pipe(untilDestroyed(this)).subscribe();
  }

  private setupFormChangesSubscriptions(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.stateChanged());

    this.form.statusChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(() => this.checkCompletion());
  }

  private checkCompletion(): void {
    if (this.form.valid || this.form.disabled) {
      this.commitCompletion();
    } else {
      this.revertCompletion();
    }
  }

  override setValidationConfiguration(data: ValidationConfigItem[]): void {
    this.validationMap = applyValidationConfig(this.form, data);
    this.cdr.markForCheck();
  }

  override setResources(data: EcSelectorResources): void {
    if (data.availableOptions) {
      data.options = data.options.filter((o) => data.availableOptions?.includes(o.value));
    }
    this.resourceBB = data;

    this.cardArray = new FormArray(data.options.map(() => new FormControl(false)));
    this.form.setControl('cards', this.cardArray);
    this.cardChecked = data.options.map(() => false);
  }

  override setState(data: EcSelectorState): void {
    this.form.patchValue(data);
  }

  override getState(): EcSelectorState {
    return this.form.getRawValue();
  }

  override getForm() {
    return this.form;
  }

  get cards(): FormArray {
    return this.form.get('cards') as FormArray;
  }

  override onPageConnection(): void {
    this.checkCompletion();
  }

  checkedChange($event: boolean) {}

  selectionChange($event: any, i: string) {
    for (const cardIndex in this.cards.controls) {
      if (+cardIndex !== +i) {
        this.cards.controls[cardIndex].setValue(false);
        this.cardChecked[cardIndex] = false;
      } else {
        this.cards.controls[cardIndex].setValue(true);
        this.cardChecked[cardIndex] = true;
      }
    }
    this.form.get('data').setValue($event.value);
  }
}
