import { ElectricalContainer } from './electrical-container';
import { Line } from '../wires/line';
import { Wire } from '../wires/wire';
import { AvailableConfigType, InputFieldConfig } from './base-component';
import { FlatJson } from './ecComponent';
import { LocalizeFn } from '@angular/localize/init';
import { Ampere } from '../core/unit.model';

declare let $localize: LocalizeFn;

export class Pole extends ElectricalContainer {
  maximumChildren = 1;

  _availableSize = [0, 10, 16, 20, 25, 32, 40, 50, 63];

  _isBlankSocket = true;

  get isBlankSocket(): boolean {
    return this._isBlankSocket;
  }

  set isBlankSocket(value: boolean) {
    this._isBlankSocket = value;
  }

  getAvailableConfig(): AvailableConfigType {
    let options: InputFieldConfig['options'] = [];

    options = this.availableSize.map((data) => ({
      value: data,
      label: data
    }));

    const poleOptions: InputFieldConfig['options'] = [
      { label: $localize`Air Conditioner`, value: 'AIR_CONDITIONER' },
      { label: $localize`Lighting Equipment`, value: 'LIGHTING' },
      { label: $localize`Water Heater`, value: 'WATER_HEATER' },
      { label: $localize`Power Socket`, value: 'POWER_SOCKET' },
      { label: $localize`Water Pump`, value: 'WATER_PUMP' },
      { label: $localize`EV Charger`, value: 'EV_CHARGER' }
    ];

    const config = [];

    if (this.canAdd && this.canAddChild) {
      config.push({
        key: 'children',
        value: this.children,
        controlType: 'crud',
        label: ``,
        options: poleOptions
      });
    }

    if (!this.canAddChild) {
      const options = this.getChildren().map((c) => ({
        label: c.name,
        value: 'DELETE_CHILDREN'
      }));
      config.push({
        key: 'children',
        value: this.children,
        controlType: 'crud',
        label: ``,
        options
      });
    }

    if (this.canConfig) {
      config.push({
        key: 'currentSize',
        value: this.currentSize,
        controlType: 'radio',
        label: `Size`,
        suffix: 'Ampere',
        options
      });
    }

    return config;
  }

  protected getWireForParent(): Wire[] {
    return [new Line()];
  }

  getFlatJson(): FlatJson {
    const json: any = super.getFlatJson();
    json.tags.push('small');
    return json;
  }

  get canAdd() {
    if (this._canAdd) {
      return true;
    }
    return this.isNew;
  }

  set canAdd(value: boolean) {
    this._canAdd = value;
  }

  get shouldBuy() {
    if (this.current <= 0) {
      return false;
    }
    return super.shouldBuy;
  }

  // get isExisting() {
  //   if (this.current) {
  //     return true;
  //   }
  //   return this._isExisting;
  // }
  //
  // set isExisting(value: boolean) {
  //   this._isExisting = value;
  // }
  set currentSize(value: Ampere) {
    super.currentSize = value;
  }

  get currentSize(): Ampere | undefined {
    if (this._currentSize) {
      return this._currentSize;
    }
    if (this.isExisting) {
      return this._currentSize;
    }
    if (this.size === 0) {
      return undefined;
    }
    return this.findAvailableSize();
  }
}
