import { DEPLOY_URL } from '@allianz/taly-common';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { envManager } from './environment-manager';

declare module window {
  const Zone: any;
  let talyZoneLoading: Promise<void> | undefined;
}

if (envManager.envData.production) {
  enableProdMode();
}


  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));

