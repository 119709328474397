import { Wire } from './wire';
import {
  PriceSummary,
  Product
} from '../../../../ec-bb/src/lib/ec-price-summary/ec-price-summary.model';
import { GroundWire, Line, Neutral } from '@ec-workspace/ec-library';
import { GROUND_SIZE_MAPPER } from './wire.const';

export class LineNeutralGround extends Wire {
  get shortName(): string {
    return `L & N & G`;
  }

  get name(): string {
    return `Line & Neutral & Ground`;
  }

  get type() {
    return 'Wire';
  }

  getPriceSummary(products: Product[]): PriceSummary[] {
    const childWires = [new Line(), new Neutral(), new GroundWire()];
    childWires.forEach((w) => {
      console.log(w);
      w.setComponents(this.input, this.output);
      w.description = this.description;
      w.length = this.length;
      if (w.className === 'GroundWire') {
        w.size = this.findSize(GROUND_SIZE_MAPPER, true);
      } else {
        w.size = this.size;
      }
    });
    return childWires.flatMap((w) => w.getPriceSummary(products));
  }

  get sizeDisplay() {
    const LN = this.size;
    const G = this.findSize(GROUND_SIZE_MAPPER, true);
    return `LN = ${LN} mm², G = ${G} mm²`;
  }
}
