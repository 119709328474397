import { ElectricalEquipment } from './electrical-equipment';
import { Ampere } from '../core/unit.model';
import { FlatJson } from '../entities/ecComponent';
import { Wire } from '../wires/wire';
import { LineNeutral } from '../wires/line-neutral';

export class ExistingEquipment extends ElectricalEquipment {
  constructor(protected _name?: string, isChildExisting: boolean = true) {
    super(_name);
    this.getChildren()?.forEach((c) => (c.isExisting = isChildExisting));
  }

  getWireForParent(): Wire[] {
    const wires = new LineNeutral();
    wires.isExisting = true;
    wires.lock();
    return [wires];
  }

  getFlatJson(): FlatJson {
    return {
      ...super.getFlatJson(),
      texts: []
    };
  }

  // setParent(parent: Component | null) {
  //   super.setParent(parent);
  //   this.getWires().forEach(w => {
  //     w.isExisting = true
  //   })
  // }

  get isCompletedState() {
    return true;
  }

  get isExisting(): boolean {
    return true;
  }

  get current(): Ampere {
    return this._current;
  }

  set current(value: Ampere) {
    this._current = (value * 0.8) / 1.25;
  }
}
