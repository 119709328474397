import { ElectricalContainer } from './electrical-container';
import { AvailableConfigType, InputFieldConfig } from './base-component';
import { Wire } from '../wires/wire';
import { LineNeutral } from '../wires/line-neutral';
import { Ampere } from '../core/unit.model';
import { METER_SIZE_LABEL_MAPPER } from '../core/constant';

export class Meter extends ElectricalContainer {
  maximumChildren = 10;

  minimumWireSizeMappers: Record<number, number> = {};

  getAvailableConfig(): AvailableConfigType {
    let sizeOptions: InputFieldConfig['options'] = [];

    sizeOptions = this.availableSize.map((data) => ({
      value: data,
      // @ts-ignore
      label: METER_SIZE_LABEL_MAPPER[data]
    }));
    const config = [];

    const poleOptions: InputFieldConfig['options'] = [
      { label: $localize`EV Charger`, value: 'EV_CHARGER' }
    ];

    if (this.canAdd && this.canAddChild) {
      config.push({
        key: 'children',
        value: this.children,
        controlType: 'crud',
        label: ``,
        options: poleOptions
      });
    }

    if (!this.canAddChild) {
      const options2 = this.getChildren().map((c) => ({
        label: c.name,
        value: 'DELETE_CHILDREN'
      }));
      config.push({
        key: 'children',
        value: this.children,
        controlType: 'crud',
        label: ``,
        options2
      });
    }

    if (this.canConfig) {
      config.push({
        key: 'currentSize',
        value: this.currentSize,
        controlType: 'radio',
        label: `Size`,
        suffix: 'Ampere',
        options: sizeOptions
      });
    }

    return config;
  }

  afterSubmit() {
    this.children.forEach((child) => {
      child.currentSize = undefined;
    });
  }

  getWireForParent(): Wire[] {
    const wire = new LineNeutral();
    wire.lock();
    wire.shouldBuy = false;
    return [wire];
  }

  getMinimumWireSize(): number | undefined {
    if (this.minimumWireSizeMappers && this.currentSize) {
      return this.minimumWireSizeMappers?.[this.currentSize];
    }
    return undefined;
  }

  set currentSize(value: Ampere) {
    super.currentSize = value;
  }

  get currentSize(): Ampere | undefined {
    if (this._currentSize) {
      return this._currentSize;
    }
    if (this.isExisting) {
      return this._currentSize;
    }
    if (this.size === 0) {
      return undefined;
    }
    return this.findAvailableSize();
  }

  get isOverCurrent(): boolean {
    const isOverCurrent = super.isOverCurrent;
    if (isOverCurrent) {
      if (this.currentSize === 15) {
        return this.current > 16;
      }
      if (this.currentSize === 45) {
        return this.current > 50;
      }
    }
    return isOverCurrent;
  }
}
