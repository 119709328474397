<h3 i18n> {{ resourceBB.title }} </h3>
<div class="container">
  <form novalidate [formGroup]="form">

    <ng-container *ngIf="!resourceBB.type">
      <nx-circle-toggle-group
        formControlName="data"
        responsive="false"
      >
        <div *ngFor="let item of resourceBB?.options">
          <nx-circle-toggle
            [value]="item.value"
            [icon]="item.icon"
            [hint]="item.hint"
            [label]="item.label"
            [circleText]="item.circleText"
            [checked]="item.value === form.value.data"
          ></nx-circle-toggle>
        </div>
        <nx-error appearance="message" class="nx-margin-top-xs">
          Please make a choice.
        </nx-error>
      </nx-circle-toggle-group>
    </ng-container>

    <ng-container *ngIf="resourceBB.type === 'CARD' && form && cardChecked">
      <div nxLayout="grid nopadding">
        <div nxRow formArrayName="cards">
          <div
            *ngFor="let card of cards.controls; index as i"
            nxCol="12,12,12,12"
          >
            <nx-selectable-card
              class="nx-margin-bottom-xs"
              [appearance]="'default'"
              (checkedChange)="checkedChange($event)"
              (selectionChange)="selectionChange($event, i)"
              [value]="resourceBB?.options[i].value"
              [(checked)]="card.value"
            >
              <div class="card">
                <img
                  [attr.width]="resourceBB?.imageWidth"
                  [src]="resourceBB?.options[i].image | talyNormalizeUrl"
                  [alt]="resourceBB?.options[i].label"
                />

                <div class="content">
                  <div>
                    <h3
                      nxCopytext
                      class="nx-font-weight-semibold nx-margin-bottom-2xs"
                    >
                      {{ resourceBB?.options[i].label }}
                    </h3>
                    <p nxCopytext="medium" class="nx-margin-bottom-2xs">

                      {{ resourceBB?.options[i].description }}
                    </p>
                  </div>
                </div>
              </div>
            </nx-selectable-card>
          </div>
        </div>
      </div>
    </ng-container>

  </form>
</div>
