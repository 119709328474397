
<taly-frame
  logoSrc="assets/logos/allianz_logo.svg"
  [sidebar]="false"
  [title]="title"
  [chromeless]="false"
  [spinner]="false"
  [navigationConfig]="navigationConfig"
  [stageConfig]="stageConfig"
  [footerConfig]="footerConfig"
>
  <router-outlet></router-outlet>

</taly-frame>





<itmp-upgrade-ie11></itmp-upgrade-ie11>
