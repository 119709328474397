import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NX_MODAL_DATA } from '@aposin/ng-aquila/modal';
import { NotificationMessage, Pole, PoleType } from '../../../ec-mdb-manager/ec-mdb-manager.model';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LocalizeFn } from '@angular/localize/init';
import { PoleFormAdaptor } from './pole-form.adaptor';
import {
  AirConditioner,
  Ampere,
  EvCharger,
  Lighting,
  LightingChild,
  PowerSocket,
  WaterHeater,
  WaterPump
} from '@ec-workspace/ec-library';

declare let $localize: LocalizeFn;

export interface PoleForm extends Pole {
  numberOfSocket?: number;
  BTU?: number;
}

export interface PoleState extends Pole {}

export interface PoleFormData {
  type: PoleType;
  label: string;
  voltage: number;
}

export interface LightningState {
  type: string;
  power: string;
  amount: number;
}

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ec-pole-form',
  templateUrl: './pole-form.component.html',
  styleUrls: ['./pole-form.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PoleFormComponent implements OnInit {
  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter();

  isPoleCurrentMode?: boolean;

  formGroup;

  pole!: any;

  formArray;

  errorMessage: NotificationMessage = {};

  state = new BehaviorSubject<PoleState>({ type: '', current: 0 });
  state$ = this.state.asObservable();

  poleSize?: Ampere;

  constructor(@Inject(NX_MODAL_DATA) public data: PoleFormData) {
    this.formGroup = this.createPoleForm(data.type, data.label);
    this.updatePole(data.type);
  }

  createPoleForm(type: PoleType, name = '') {
    const formControls: any = {
      name: new FormControl(name),
      type: new FormControl(type),
      current: new FormControl(0, [Validators.required, Validators.min(1)]),
      power: new FormControl(0, [Validators.required, Validators.min(1)])
    };
    if (type === 'POWER_SOCKET') {
      formControls['numberOfSocket'] = new FormControl(1, Validators.required);
    } else if (type === 'AIR_CONDITIONER') {
      formControls['BTU'] = new FormControl(12000, Validators.required);
    } else if (type === 'WATER_HEATER') {
    } else if (type === 'LIGHTING') {
      formControls['equipments'] = this.createFormArray();
      this.formArray = formControls['equipments'];
    } else if (type === 'EV_CHARGER') {
    }

    return new FormGroup(formControls);
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.updatePole(value.type);

      const current = this.pole?.current;

      this.poleSize = 0;

      this.state.next({
        name: value.name,
        type: value.type,
        power: this.pole.power,
        current
      });
    });
    this.updatePole(this.data.type);
    this.formGroup.updateValueAndValidity();
  }

  updatePole(type: PoleType) {
    if (type === 'POWER_SOCKET') {
      const powerSocket = new PowerSocket(this.formGroup.value.name);
      powerSocket.numberOfSocket = this.formGroup.value.numberOfSocket;
      this.pole = PoleFormAdaptor.convertToPoleState(
        powerSocket,
        type,
        this.poleSize,
        this.data.voltage
      );
    } else if (type === 'AIR_CONDITIONER') {
      const air = new AirConditioner(this.formGroup.value.name);
      air.BTU = this.formGroup.value.BTU;
      this.pole = PoleFormAdaptor.convertToPoleState(air, type, this.poleSize, this.data.voltage);
    } else if (type === 'WATER_HEATER') {
      const waterHeater = new WaterHeater(this.formGroup.value.name);
      waterHeater.power = this.formGroup.value.power;
      this.pole = PoleFormAdaptor.convertToPoleState(
        waterHeater,
        type,
        this.poleSize,
        this.data.voltage
      );
    } else if (type === 'LIGHTING') {
      const lightingChildData: [] = this.formArray?.value ?? [];
      const lighting = new Lighting(this.formGroup.value.name);
      lighting.setChildren(
        lightingChildData.map((c: any) => {
          const l = new LightingChild(c.type);
          l.power = c.power;
          l.amount = c.amount;
          return l;
        })
      );
      this.pole = PoleFormAdaptor.convertToPoleState(
        lighting,
        type,
        this.poleSize,
        this.data.voltage
      );
    } else if (type === 'EV_CHARGER') {
      const evCharger = new EvCharger(this.formGroup.value.name);
      evCharger.power = this.formGroup.value.power;
      evCharger.mainDevice = evCharger;
      this.pole = PoleFormAdaptor.convertToPoleState(
        evCharger,
        type,
        this.poleSize,
        this.data.voltage
      );
    } else if (type === 'CONSUMER_BOX') {
    } else if (type === 'WATER_PUMP') {
      const waterPump = new WaterPump(this.formGroup.value.name);
      waterPump.power = this.formGroup.value.power;
      this.pole = PoleFormAdaptor.convertToPoleState(
        waterPump,
        type,
        this.poleSize,
        this.data.voltage
      );
    }
    console.log(this.pole);
  }

  newFormGroup() {
    return new FormGroup({
      type: new FormControl(''),
      power: new FormControl(0),
      amount: new FormControl(0)
    });
  }

  createFormArray() {
    return new FormArray([this.newFormGroup()]);
  }

  addLightingRow() {
    this.formArray.push(this.newFormGroup());
    this.formArray.updateValueAndValidity();
    this.formGroup.updateValueAndValidity();
  }

  removeRow(index: number) {
    this.formArray.removeAt(index);
  }

  getFormGroup(i: number): FormGroup {
    return this.formArray.controls[i] as FormGroup;
  }

  private findPoleSize(current: Ampere, poleSizeOptions: Ampere[]) {
    delete this.errorMessage['poleSize'];
    for (const m of poleSizeOptions) {
      if (m > current) {
        return m;
      }
    }
    this.errorMessage['poleSize'] = $localize`Can not find the proper size of pole`;
    return 0;
  }

  isFormValid() {
    return (
      (this.state.value.current > 0 || this.formGroup.get('poleSize')?.value > 0) &&
      Object.keys(this.errorMessage).length <= 0
    );
  }

  submit() {
    this.updatePole(this.data.type);
    this.save.emit(this.pole);
  }
}
