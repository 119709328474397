import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoleFormComponent } from './pole-form.component';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { ReactiveFormsModule } from '@angular/forms';
import { NX_MODAL_DATA, NxModalModule } from '@aposin/ng-aquila/modal';
import { NxCopytextModule } from '@aposin/ng-aquila/copytext';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NxRadioModule } from '@aposin/ng-aquila/radio-button';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxTableModule } from '@aposin/ng-aquila/table';
import { NxDropdownModule } from '@aposin/ng-aquila/dropdown';
import { NxMessageModule } from '@aposin/ng-aquila/message';

@NgModule({
  declarations: [PoleFormComponent],
  imports: [
    CommonModule,
    NxFormfieldModule,
    ReactiveFormsModule,
    NxCopytextModule,
    NxInputModule,
    NxIconModule,
    NxRadioModule,
    NxButtonModule,
    NxGridModule,
    NxTableModule,
    NxDropdownModule,
    NxMessageModule,
    NxModalModule
  ],
  exports: [PoleFormComponent],
  providers: [
    {
      provide: NX_MODAL_DATA,
      useValue: {
        label: 'LIGHTING',
        type: 'LIGHTING',
        voltage: 230
      }
    }
  ]
})
export class PoleFormModule {}
