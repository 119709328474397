import { ElectricalEquipment } from './electrical-equipment';
import { Ampere } from '../core/unit.model';
import { AvailableConfigType } from '../entities/base-component';

export class AirConditioner extends ElectricalEquipment {
  protected _BTU = 0;

  get current(): Ampere {
    return this.power / this._voltage;
  }

  get power() {
    return (this._BTU * 1500) / 12000;
  }

  get BTU() {
    return this._BTU;
  }

  set BTU(BTU: number) {
    this._BTU = BTU;
  }

  getAvailableConfig(): AvailableConfigType {
    return [
      {
        key: 'BTU',
        value: this.BTU,
        controlType: 'number',
        suffix: 'BTU'
      }
    ];
  }
}
