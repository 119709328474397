import { Meter } from '../../entities/meter';
import { EvChargerInstall } from './ev-charger-install.director';
import { MeterMock } from '../../entities/meter-mock';

export class AddMeterInstall extends EvChargerInstall {
  isSkipConfigExistingDevice = true;

  initialForConfigExistingDevice() {
    const meter = new MeterMock('Existing Meter');
    this.meters.push(meter);
    this.builder.addComponentToRoot(meter);
  }

  initialForConfigNewDeviceStep1(): void {
    // add new meter
    const meter2 = new Meter(`New Meter`);
    meter2.isExisting = false;
    meter2.canAdd = true;
    meter2.maximumChildren = 1;
    this.builder.addComponentToRoot(meter2);
  }
}
