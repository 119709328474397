import { EcComponent } from './ecComponent';
import { AvailableConfigType } from './base-component';
import { Wire } from '../wires/wire';

export class Clamp extends EcComponent {
  getAvailableConfig(): AvailableConfigType | null {
    return null;
  }

  getAvailableTypeOptions(): AvailableConfigType | null {
    return null;
  }

  protected getWireForParent(): Wire[] {
    return [];
  }
}
