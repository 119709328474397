import { ElectricalDirector } from './electrical-director';
import { ConsumerBox } from '../../containers/consumer-box';
import { Meter } from '../../entities/meter';
import { ExistingEquipment } from '../../equipments/existing-equipment';
import { Ampere } from '../../core/unit.model';
import { EcComponent } from '../../entities/ecComponent';
import { Pole } from '../../entities/pole';
import { Rcbo2Pole } from '../../entities/rcbo-2-pole';
export class MaintenanceDirector extends ElectricalDirector {
  targetConsumerBox!: ConsumerBox;

  isSkipConfigExistingDevice = false;

  initialForConfigExistingDevice(): void {
    const meter = new Meter();
    meter.isExisting = true;
    const MCB = new Rcbo2Pole();
    MCB.isExisting = true;
    meter.add(MCB);

    const consumerBox = new ConsumerBox(`ConsumerBox`, undefined, undefined, true, false);
    consumerBox.isEnabledForceSetCurrent = true;
    consumerBox.addDefaultChildHook = (c) => {
      c.isExisting = false;
    };
    // consumerBox.afterAdd = (c) => {
    //   this.addExistingEquipment(c.name, c.currentSize ?? 0, consumerBox);
    // }
    consumerBox.socketNumber = 4;
    consumerBox.isExisting = true;
    consumerBox.add(MCB);
    consumerBox.mainInsider = MCB;
    this.targetConsumerBox = consumerBox;

    this.builder.addComponentToRoot(meter);
  }

  initialForConfigNewDeviceStep1(): void {
    this.targetConsumerBox.addGroundBar();

    this.traversal.getAllComponentsArray().forEach((c) => {
      if (c instanceof Pole) {
        if (c.current !== 0) {
          this.addExistingEquipment(
            `Existing Equipment ${c.name}`,
            c.currentSize ?? 0,
            this.targetConsumerBox,
            c
          );
        }
      }
    });
    this.traversal.recursive();
  }

  addExistingEquipment(name: string, currentSize: Ampere, consumerBox: ConsumerBox, pole: Pole) {
    const existingEquipment = new ExistingEquipment(name, false);
    existingEquipment.current = currentSize;
    consumerBox.connectPole(existingEquipment, pole);

    const groundBar = consumerBox.groundBar.cloneForLink();
    existingEquipment.add(groundBar);
    groundBar.getWires().forEach((w) => {
      w.isExisting = false;
      w.isMaintenance = true;
    });

    return existingEquipment;
  }

  removeExistingEquipment(name: string) {
    const component = this.traversal.findByName(name);
    if (component) {
      this.traversal.findUp(component, (c: EcComponent) => {
        if (c instanceof Pole) {
          c.remove(component);
        }
      });
    }
  }

  testing() {
    //   this.addExistingEquipment(`Air Conditioner 1`, 30);
    //   this.addExistingEquipment(`Plug Socket 1`, 20);
    //   this.addExistingEquipment(`Water Header 1`, 50);
    //   this.removeExistingEquipment(`Plug Socket 1`);
    //   this.addExistingEquipment(`Water Header 2`, 50);
  }

  testingCompleteAllExising() {
    console.log('testingCompleteAllExising');
    this.traversal.findChildren(this.traversal.root, (c) => {
      console.log(c.className);
      if (c.className === 'Meter') {
        c.currentSize = 50;
      }
      if (c.className === 'Rcbo2Pole') {
        c.currentSize = 50;
      }
    });
    this.targetConsumerBox.poles[0].currentSize = 20;
    this.targetConsumerBox.poles[1].currentSize = 30;
  }
}
