import { AvailableConfigType, InputFieldConfig } from './base-component';
import { Mcb2Pole } from './mcb-2-pole';
import { Ampere } from '../core/unit.model';

/**
 * For EvCharger
 */
export class Rcd2PoleTypeB extends Mcb2Pole {
  isOverCurrentProtection = true;

  _availableSize = [20, 40];

  _name = `RCD 2P TypeB`;

  getAvailableConfig(): AvailableConfigType {
    let options: InputFieldConfig['options'] = [];
    if (parent) {
      options = [20, 40].map((data) => ({
        value: data,
        label: `${data} A`
      }));
    }

    return [
      {
        key: 'currentSize',
        value: this.currentSize,
        controlType: 'radio',
        label: `Size`,
        suffix: 'Ampere',
        options
      }
    ];
  }
}
