import {
  NgxPfeModule,
  NgxPfeModuleConfiguration,
  NGX_PFE_CONFIGURATION,
  NGX_PFE_FLOW_CONFIGURATION

} from '@allianz/ngx-pfe';
import { PfeDevToolsModule } from '@allianz/ngx-pfe/pfe-dev-tools';
import { ACL_STORE_ADAPTER_TOKEN } from '@allianz/taly-acl/angular';

import { PfeAclExpressionAdapter } from '@allianz/taly-pfe-connector';
import { NgModule } from '@angular/core';
import { envManager } from '../environment-manager';
import { pages } from './pages-mapping';
import { TalyFrameNavigationService } from '@allianz/taly-common/frame';
import { PfeFrameNavigationService } from '@allianz/taly-pfe-connector';
import { PFE_CONFIG } from './pfe-config'
import { PfeTrackingModule } from '@allianz/ngx-pfe/tracking';




export function ngxPFEConfigFactory() {
  const defaultTenant = 'bb-pfe';
  const applicationID = 'gdf_app';

  const config: NgxPfeModuleConfiguration = {
    pageMapping: pages,
    tenant: defaultTenant,
    applicationID,
    config_api_endpoint: 'http://localhost:8080/config',
    service_activator_endpoint: envManager.envData.BFF_BASE_URL,
    production: envManager.envData.production,
    tracking: {
      adobeUrl: envManager.envData.TRACKING_ADOBE_URL,
      appName: envManager.envData.TRACKING_APP_NAME
    }
  };

  return config;
}

@NgModule({
  imports: [
    NgxPfeModule.forRoot({
      provide: NGX_PFE_CONFIGURATION,
      useFactory: ngxPFEConfigFactory
    })
    ,
    PfeTrackingModule
    
  ],
  exports: [PfeDevToolsModule],
  declarations: [],
  providers: [

    {
      provide: NGX_PFE_FLOW_CONFIGURATION,
      useValue: PFE_CONFIG
    },
    {
      provide: ACL_STORE_ADAPTER_TOKEN,
      useClass: PfeAclExpressionAdapter
    },
    {
      provide: TalyFrameNavigationService,
      useClass: PfeFrameNavigationService
    }
    
  ]
})
export class PfeIntegrationModule {}
