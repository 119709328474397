import { EvChargerInstall } from './ev-charger-install.director';
import { ConsumerBox } from '../../containers/consumer-box';
import { Meter } from '../../entities/meter';

/**
 * Install Type 1
 */
export class IncreaseMcbInstall extends EvChargerInstall {
  isPoleCanAddAllDevices = true;

  initialForConfigExistingDevice() {
    const mcb2p = this.getNewMainDoublePole();
    mcb2p.isExisting = true;
    mcb2p.isPoleCanAddAllDevices = this.isPoleCanAddAllDevices;
    this.MCB1 = mcb2p;

    const meter = new Meter();
    meter.isExisting = true;
    this.meters.push(meter);

    meter.add(mcb2p);

    const consumerBox = new ConsumerBox();
    consumerBox.isEnabledForceSetCurrent = true;
    consumerBox.socketNumber = 4;
    this.targetConsumerBox = consumerBox;

    mcb2p.setBoxContainer(consumerBox);
    this.builder.addComponentToRoot(meter);
  }

  initialForConfigNewDeviceStep1(): void {
    super.initialForConfigNewDeviceStep1();
  }

  testingCompleteAllExising() {
    super.testingCompleteAllExising();
  }
}
