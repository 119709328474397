import { ElectricalEquipment } from './electrical-equipment';
import { AvailableConfigType } from '../entities/base-component';
import { Ground } from '../entities/ground';
import { EcComponent } from '../entities/ecComponent';

export class EvCharger extends ElectricalEquipment {
  getAvailableConfig(): AvailableConfigType {
    return [
      {
        key: 'power',
        value: this.power,
        label: 'Power',
        controlType: 'radio',
        options: [
          { label: '3.7 kw', value: 3700 },
          { label: '7.4 kw', value: 7400 }
        ]
      }
    ];
  }

  addDefaultChild(defaultChild: EcComponent = new Ground()) {
    // this.add(defaultChild)
  }
}
