import { BaseComponent } from './entities/base-component';
import { EcComponent } from './entities/ecComponent';
import { DoublePole } from './entities/double-pole';
import { ElectricalContainer } from './entities/electrical-container';
import { Ground } from './entities/ground';
import { Mcb2Pole } from './entities/mcb-2-pole';
import { Meter } from './entities/meter';
import { MeterMock } from './entities/meter-mock';
import { MockComponent } from './entities/mock-component';
import { Pole } from './entities/pole';
import { Rcbo1Pole } from './entities/rcbo-1-pole';
import { Rcbo2Pole } from './entities/rcbo-2-pole';
import { SinglePole } from './entities/single-pole';
import { AirConditioner } from './equipments/air-conditioner';
import { ElectricalEquipment } from './equipments/electrical-equipment';
import { EvCharger } from './equipments/ev-charger';
import { ExistingEquipment } from './equipments/existing-equipment';
import { Lighting } from './equipments/lighting';
import { LightingChild } from './equipments/lighting-child';
import { PowerSocket } from './equipments/power-socket';
import { WaterPump } from './equipments/water-pump';
import { GroundWire } from './wires/ground-wire';
import { Line } from './wires/line';
import { Neutral } from './wires/neutral';
import { UnknownWire } from './wires/unknown-wire';
import { Wire } from './wires/wire';
import { Authority } from './authorities/authority';
import { PEA } from './authorities/pea';
import { MEA } from './authorities/mea';
import { BoxContainer } from './containers/box-container';
import { ConsumerBox } from './containers/consumer-box';
import { LineNeutral } from './wires/line-neutral';
import { Rcd2PoleTypeB } from './entities/rcd2-pole-type-b';
import { GroundRod } from './entities/ground-rod';
import { Clamp } from './entities/clamp';
import { WaterHeater } from './equipments/water-heater';

export const STORE_CLASS = {
  //Entity
  BaseComponent,
  EcComponent,
  ElectricalContainer,
  Ground,
  GroundRod,
  Clamp,
  Mcb2Pole,
  Meter,
  DoublePole,
  MeterMock,
  MockComponent,
  Pole,
  Rcbo1Pole,
  Rcbo2Pole,
  Rcd2PoleTypeB,
  SinglePole,

  // Equipment
  AirConditioner,
  ElectricalEquipment,
  EvCharger,
  ExistingEquipment,
  Lighting,
  LightingChild,
  PowerSocket,
  WaterHeater,
  WaterPump,

  //Wire
  GroundWire,
  Line,
  Neutral,
  UnknownWire,
  Wire,
  LineNeutral,

  //Authority
  Authority,
  PEA,
  MEA,

  //Container
  BoxContainer,
  ConsumerBox
};
