import { EcComponent, FlatJson } from '../entities/ecComponent';
import { ElectricalContainer } from '../entities/electrical-container';
import { AVAILABLE_MCB_SIZE, AVAILABLE_METER_SIZE } from '../core/constant';
import { Meter } from '../entities/meter';
import { AvailableConfigType } from '../entities/base-component';

export abstract class Authority extends ElectricalContainer {
  private _availableMeterSize: AVAILABLE_METER_SIZE[] = [];
  private _availableMCBSize: AVAILABLE_MCB_SIZE[] = [];

  get availableMeterSize(): AVAILABLE_METER_SIZE[] {
    return this._availableMeterSize;
  }

  set availableMeterSize(value: AVAILABLE_METER_SIZE[]) {
    this._availableMeterSize = value;
  }

  get availableMCBSize(): AVAILABLE_MCB_SIZE[] {
    return this._availableMCBSize;
  }

  set availableMCBSize(value: AVAILABLE_MCB_SIZE[]) {
    this._availableMCBSize = value;
  }

  get minimumWireSizeMappers() {
    return {};
  }

  get isExisting() {
    return true;
  }

  get isError() {
    return false;
  }

  add(composite: EcComponent): void {
    composite.voltage = this.voltage;
    this.setAvailableChidrenSize(composite);
    super.add(composite);
  }

  get isCompletedState(): boolean {
    return true;
  }

  getFlatJson(): FlatJson {
    const json: FlatJson = super.getFlatJson();
    const texts: string[] = [`${this.voltage} V`];
    json.texts = texts;
    return json;
  }

  getAvailableConfig(): AvailableConfigType {
    return null;
  }

  get canConfig() {
    return false;
  }

  private setAvailableChidrenSize(composite: EcComponent) {
    if (composite instanceof Meter) {
      composite.availableSize = this.availableMeterSize;
      composite.minimumWireSizeMappers = this.minimumWireSizeMappers;
    }

    composite.getChildren().forEach((child) => {
      this.setAvailableChidrenSize(child);
    });
  }
}
