import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractBuildingBlock, createBuildingBlockProvider } from '@allianz/taly-core';
import {
  EcNewEquipmentConfigureResources,
  EcNewEquipmentConfigureState
} from './ec-new-equipment-configure.model';
import { EcFacadeService } from '../shared/services/ec-facade.service';
import { PfeBusinessService } from '@allianz/ngx-pfe';
import { INSTRUCTIONS } from '../ec-equipment-configure/ec-equipment-configure.component';

@UntilDestroy()
@Component({
  selector: 'bb-ec-new-equipment-configure',
  templateUrl: 'ec-new-equipment-configure.component.html',
  styleUrls: ['ec-new-equipment-configure.component.scss'],
  providers: [createBuildingBlockProvider(EcNewEquipmentConfigureComponent)]
})
export class EcNewEquipmentConfigureComponent
  extends AbstractBuildingBlock<EcNewEquipmentConfigureState, EcNewEquipmentConfigureResources>
  implements OnInit
{
  instruction = '';
  isKeepStateConfigNewDevice = false;

  constructor(
    injector: Injector,
    private cdr: ChangeDetectorRef,
    protected ecFacadeService: EcFacadeService,
    protected pfeBusinessService: PfeBusinessService
  ) {
    super(injector);

    this.ecFacadeService.startOverProcessNewDevice$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.startOverProcessNewDevice(data);
    });
  }

  private manageDataStateSession() {
    const navigatingFrom = this.pfeBusinessService.getValueByExpression(
      '$._pfe.navigationState.navigatingFrom'
    );
    console.log(`navigatingFrom ${navigatingFrom}`);
    if (navigatingFrom === 'price-summary') {
      this.isKeepStateConfigNewDevice = true;
    }
  }

  override ngOnInit(): void {}

  private checkCompletion(): void {
    if (this.ecFacadeService.isCompleted()) {
      this.commitCompletion();
    } else {
      this.revertCompletion();
    }
  }

  override setResources(data: EcNewEquipmentConfigureResources): void {
    console.log(
      'The Building Block EcNewEquipmentConfigure received the following resources:',
      data
    );
    this.manageDataStateSession();
    this.ecFacadeService.startOverProcessNewDevice$.next(data);
  }

  private startOverProcessNewDevice(data: EcNewEquipmentConfigureResources) {
    if (!this.isKeepStateConfigNewDevice) {
      if (!this.ecFacadeService?.director) {
        this.ecFacadeService.setUpDirector(data);
      }

      if (this.ecFacadeService?.director.isSkipConfigExistingDevice) {
        this.ecFacadeService.initialInstallation(data);
        this.ecFacadeService.initialForConfigExistingDevice();
      }

      this.ecFacadeService.initialForConfigNewDevice();
      this.ecFacadeService.traversal.recursive();
      this.ecFacadeService.isInitialNewEquipment = true;
    }

    this.instruction =
      INSTRUCTIONS[`CONFIG_NEW_DEVICE_${this.ecFacadeService.director.name}_INSTRUCTION`];

    this.ecFacadeService.traversal.componentsState$.pipe(untilDestroyed(this)).subscribe(() => {
      this.stateChanged();
      this.checkCompletion();
    });
  }

  override setState(data: EcNewEquipmentConfigureState): void {}

  override getState(): EcNewEquipmentConfigureState {
    return {
      componentsState: this.ecFacadeService.traversal.componentsState$.value
    };
  }

  onPageDisconnected() {}

  override onPageConnection(): void {
    console.log('The Building Block EcNewEquipmentConfigure got connected to the page');
    this.checkCompletion();
  }
}
