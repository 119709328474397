import { ElectricalContainer } from './electrical-container';
import { AvailableConfigType } from './base-component';
import { Wire } from '../wires/wire';
import { FlatJson } from './ecComponent';
import { LineNeutral } from '../wires/line-neutral';

export class MockComponent extends ElectricalContainer {
  getAvailableConfig(): AvailableConfigType | null {
    return null;
  }

  get isExisting() {
    return true;
  }

  protected getWireForParent(): Wire[] {
    return [new LineNeutral()];
  }

  get isCompletedState(): boolean {
    return true;
  }

  getFlatJson(): FlatJson {
    const json: any = super.getFlatJson();
    const texts: string[] = [];
    json.texts = texts;
    return json;
  }
}
