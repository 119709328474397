import { ElectricalContainer } from './electrical-container';
import { Wire } from '../wires/wire';
import { LineNeutral } from '../wires/line-neutral';
import { AvailableConfigType } from './base-component';
import { DoublePoleType } from '../design-pattern/builder/electrical-director';

export class DoublePole extends ElectricalContainer {
  isOverCurrentProtection = false;

  /**
   * ผลรวม ค่ากระแสโหลดจริงของแต่ละ
   * เซอร์กิตย่อย x 1.25 = ขนาดเมนเบรคเกอร์
   */
  toleranceFactorSize = 1;

  get type(): DoublePoleType {
    return super.type as DoublePoleType;
  }

  set type(value: DoublePoleType) {
    this.isOverCurrentProtection = !(this.type === 'Mcb2Pole');
    if (value === 'Rcd2PoleTypeB') {
      this._availableSize = [20, 40];
    }
    if (value === 'Mcb2Pole') {
      this._availableSize = [16, 20, 25, 32, 40, 50, 63, 80, 100, 125];
    }
    if (value === 'Rcbo2Pole') {
      this._availableSize = [16, 20, 25, 32, 40, 50, 63, 80, 100];
    }

    super.type = value;
  }

  getAvailableTypeOptions(): AvailableConfigType {
    if (this.lockConfigForNewDevice) {
      return null;
    }
    return [
      {
        key: 'type',
        value: 'Mcb2Pole',
        controlType: 'radio',
        label: `2 Pole Type`,
        onChange: () => {
          alert('XXX');
        },
        options: [
          {
            label: 'MCB 2 Pole',
            value: 'Mcb2Pole'
          },
          {
            label: 'RCBO 2 Pole',
            value: 'Rcbo2Pole'
          },
          {
            label: 'RCD 2 Pole Type B',
            value: 'Rcd2PoleTypeB'
          }
        ]
      }
    ];
  }

  protected getWireForParent(): Wire[] {
    return [new LineNeutral()];
  }
}
