import { EcComponent, FlatJson } from './ecComponent';
import { Ampere, Voltage } from '../core/unit.model';
import { Wire } from '../wires/wire';
import { AvailableConfigType } from './base-component';
import { LineNeutral } from '../wires/line-neutral';

/**
 * Composite Abstract
 */
export abstract class ElectricalContainer extends EcComponent {
  public _availableSize: number[] = [];

  get availableSize(): number[] {
    if (!this.canConfigForNewDevice) {
      return this._availableSize;
    }
    return this._availableSize.filter((avz) => avz >= this.getMathFloorSize());
  }

  set availableSize(value: number[]) {
    this._availableSize = value;
  }

  protected maximumChildren = 0;

  protected children: EcComponent[] = [];

  private _canAddChild = true;

  public canConfigForNewDevice = false;

  public lockConfigForNewDevice = false;

  hasEvChargerChild = false;

  constructor(_name?: string, protected _currentSize?: Ampere, protected _isExisting?: boolean) {
    super(_name);
    this.currentSize = _currentSize;
    this._isExisting = !!_isExisting;
  }

  get canAddChild(): boolean {
    return this.getChildren().length < this.maximumChildren;
  }

  set canAddChild(value: boolean) {
    this._canAddChild = value;
  }

  get newCurrentSize(): number | undefined {
    if (this.isOverCurrent) {
      return this.findAvailableSize();
    }
    return undefined;
  }

  set newCurrentSize(value: number) {
    this._newCurrentSize = value;
  }

  get currentSize(): Ampere | undefined {
    if (!this.canConfigForNewDevice) {
      return this._currentSize;
    }
    if (super.currentSize) {
      return super.currentSize;
    }
    if (this.size === 0) {
      return undefined;
    }
    return this.findAvailableSize();
  }

  findAvailableSize() {
    return this.availableSize.find((avs) => avs >= this.getMathFloorSize());
  }

  getMathFloorSize() {
    let multiply = 1.25;
    this.findChildren(this, (component) => {
      if (component.type === 'EvCharger') {
        this.hasEvChargerChild = true;
        multiply = 1;
      }
    });
    if (this.mainDevice?.type === 'EvCharger') {
      console.log(`main device is EvCharger ${this.name}`);
      multiply = 1;
    }
    /**
     * multiply 1.25 when it is main breaker
     * กระส x multiply
     */
    return this.size * multiply;
  }

  findChildren(component: EcComponent | null, callback: (component: EcComponent) => void) {
    if (!component) {
      return;
    }
    callback(component);
    if (component?.getChildren().length <= 0) {
      return;
    }
    component?.getChildren().forEach((c) => {
      this.findChildren(c, callback);
    });
  }

  set currentSize(value: Ampere | undefined) {
    super.currentSize = value;
    this.forceSetCurrent(value);
  }

  set isExisting(value: boolean) {
    this._isExisting = value;
  }

  get isExisting() {
    return !!this._isExisting;
  }

  get current(): Ampere {
    if (this._current) {
      return this._current;
    }
    return this.children.reduce((acc, child) => acc + child.current, 0);
  }

  get voltage(): Voltage {
    return this._voltage;
  }

  set voltage(v: Voltage) {
    this._voltage = v;
    this.children.forEach((c) => (c.voltage = v));
  }

  /**
   * A composite object can add or remove other components (both simple or
   * complex) to or from its child list.
   */
  public add(child: EcComponent): void {
    if (this.maximumChildren && this.children.length >= this.maximumChildren) {
      throw new Error(`${this.children.length} > ${this.maximumChildren}`);
    }
    this.children.push(child);
    child.voltage = this.voltage;
    child.setParent(this);
  }

  setChildren(children: EcComponent[]) {
    this.children = [];
    children.forEach((c) => this.add(c));
  }

  public addList(components: EcComponent[]): void {
    components.forEach((c) => this.add(c));
  }

  public remove(component: EcComponent): void {
    const componentIndex = this.children.indexOf(component);
    this.children.splice(componentIndex, 1);

    component.setParent(null);
  }

  public isComposite(): boolean {
    return true;
  }

  public getChildren(): EcComponent[] {
    return this.children;
  }

  getRecursiveName(): string[] {
    if (this.children.length <= 0) {
      return [this.name];
    }
    return this.children.map((c) => c.getRecursiveName());
  }

  getAvailableConfig(): AvailableConfigType {
    return [
      {
        key: 'currentSize',
        value: this.currentSize,
        controlType: 'number',
        label: `Size`,
        suffix: 'Ampere'
      }
    ];
  }

  getAvailableTypeOptions(): AvailableConfigType {
    return null;
  }

  get isError(): boolean {
    if (this._availableSize.length <= 0) {
      return false;
    }
    if (this.canConfigForNewDevice) {
      if (this.currentSize === undefined && this.availableSize.length <= 0) {
        return true;
      }
    }

    // if (this.currentSize && this.current > this.currentSize) {
    //   return true;
    // }

    if (!this.currentSize) {
      if (this.getMathFloorSize() > Math.max(...this.availableSize)) {
        return true;
      }
    }

    return this.current > Math.max(...this.availableSize);
  }

  get isCompletedState() {
    if (this.isError) {
      return false;
    }
    if (this.currentSize) {
      if (this.availableSize.length <= 0) {
        return false;
      }
      return this.availableSize.indexOf(this.currentSize) >= 0;
    }
    return super.isCompletedState;
  }

  getFlatJson(): FlatJson {
    const json = super.getFlatJson();
    json.texts.push(`size: ${this.currentSize?.toFixed(2) ?? '?'} A`);
    if (this.newCurrentSize !== undefined) {
      json.texts.push(`new size: ${this.newCurrentSize?.toFixed(2) ?? '?'} A`);
    }
    return {
      ...json
    };
  }

  protected getWireForParent(): Wire[] {
    return [new LineNeutral()];
  }

  get canConfig() {
    if (this.canConfigForNewDevice) {
      return true;
    }
    if (this._isLock) {
      return false;
    }
    return this.isExisting;
  }

  get size() {
    return this.current;
  }
}
