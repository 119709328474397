import { ElectricalContainer } from '../entities/electrical-container';
import { Ground } from '../entities/ground';
import { EcComponent, FlatJson } from '../entities/ecComponent';
import { GroundWire } from '../wires/ground-wire';
import { AvailableConfigType } from '../entities/base-component';
import { Wire } from '../wires/wire';

export class GroundBar extends ElectricalContainer {
  maximumChildren = 2;

  // get isExisting() {
  //   return true;
  // }

  get isCompletedState() {
    return true;
  }

  get isError() {
    return false;
  }

  get canConfig(): boolean {
    if (this._isLock) {
      return false;
    }
    return false;
  }

  addDefaultChild(defaultChild: EcComponent = new Ground()) {
    this.add(defaultChild);
  }

  getFlatJson(): FlatJson {
    const json: FlatJson = super.getFlatJson();
    const texts: string[] = [];
    json.texts = texts;
    return json;
  }

  getWireForParent() {
    const wire = new GroundWire();
    return [wire];
  }

  public addWire(wire: Wire) {
    this.wires.push(wire);
    if (this.parent) {
      wire.setComponents(this.parent, this);
      wire.isExisting = this.parent.isExisting;
    }
    wire.mainDevice = this.mainDevice;
  }

  public cloneForLink(): this {
    const clone = Object.create(this);
    clone.id = GroundBar.uniqueID();
    clone.container = null;
    clone.setChildren([]);
    clone.wires = [];

    return clone;
  }

  getAvailableConfig(): AvailableConfigType | null {
    return undefined;
  }

  public add(child: EcComponent): void {
    if (this.maximumChildren && this.children.length >= this.maximumChildren) {
      throw new Error(`${this.children.length} > ${this.maximumChildren}`);
    }
    this.children.push(child);
    child.voltage = this.voltage;
    if (child instanceof Ground) {
      child.setParent(this);
    }
  }
}
