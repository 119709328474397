import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TalyCoreModule } from '@allianz/taly-core';
import { CcValidationErrorsModule } from '@allianz/common-building-block-components';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { EcSelectorComponent } from './ec-selector.component';
import { NxCircleToggleModule } from '@aposin/ng-aquila/circle-toggle';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxCardModule } from '@aposin/ng-aquila/card';
import { NxPopoverModule } from '@aposin/ng-aquila/popover';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NxCopytextModule } from '@aposin/ng-aquila/copytext';
import { NormalizeUrlModule } from '@allianz/taly-common';

@NgModule({
  declarations: [EcSelectorComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TalyCoreModule,
    CcValidationErrorsModule,
    NxHeadlineModule,
    NxGridModule,
    NxFormfieldModule,
    NxInputModule,
    NxCircleToggleModule,
    NxButtonModule,
    NxCardModule,
    NxPopoverModule,
    NxIconModule,
    NxCopytextModule,
    NormalizeUrlModule
  ],
  exports: [EcSelectorComponent]
})
export class EcSelectorModule {}
