import { ElectricalContainer } from '../entities/electrical-container';
import { Ground } from '../entities/ground';
import { Ampere, Watt } from '../core/unit.model';
import { Pole } from '../entities/pole';
import { EcComponent, FlatJson } from '../entities/ecComponent';
import { AvailableConfigType } from '../entities/base-component';
import { LineNeutral } from '../wires/line-neutral';
import { LineNeutralGround } from '../wires/line-neutral-ground';

/**
 * Leaf
 */
export abstract class ElectricalEquipment extends ElectricalContainer {
  maximumChildren = 1;

  constructor(protected _name?: string, protected _power = 0, protected _isExisting?: boolean) {
    super(_name, undefined, _isExisting);
    this.addDefaultChild();
  }

  addDefaultChild(defaultChild: EcComponent = new Ground()) {}

  get current(): Ampere {
    return this._power / this._voltage;
  }

  get power(): Watt {
    return this._power;
  }

  set power(value: Watt) {
    this._power = value;
  }

  getWireForParent() {
    if (this.parent instanceof Pole) {
      return [new LineNeutralGround()];
    }
    return [new LineNeutral()];
  }

  // setParent(parent: Component | null) {
  //   super.setParent(parent);
  //   // if (parent instanceof Pole && parent?.container ) {
  //   //   // console.warn(parent.container.getChildren());
  //   //   const groundBar = parent?.container.getChildren().find((c) => c.className === 'GroundBar');
  //   //   console.log(groundBar);
  //   //   const wire = new GroundWire();
  //   //   if (groundBar)
  //   //     wire.setComponents(this, groundBar);
  //   //   this.wires.push(wire);
  //   // }
  //
  //   // wire.setComponents(this, )
  //   // parent?.container?.addWires([wire])
  // }

  getAvailableConfig(): AvailableConfigType {
    return [
      {
        key: 'power',
        value: this.power,
        controlType: 'number',
        suffix: 'Watt'
      }
    ];
  }

  getFlatJson(): FlatJson {
    const json: FlatJson = super.getFlatJson();
    const texts: string[] = [];
    texts.push(`power: ${Number(this.power).toFixed(0)} W`);
    json.texts = texts;
    return json;
  }

  get canConfig() {
    if (this._isLock) {
      return false;
    }
    return !this.isExisting;
  }
}
