import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TalyCoreModule } from '@allianz/taly-core';
import { CcValidationErrorsModule } from '@allianz/common-building-block-components';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { EcPriceSummaryComponent } from './ec-price-summary.component';
import { NxDynamicTableModule } from '@aposin/ng-aquila/dynamic-table';
import { NxSwipebarModule } from '@aposin/ng-aquila/swipebar';
import { NxTableModule } from '@aposin/ng-aquila/table';

@NgModule({
  declarations: [EcPriceSummaryComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TalyCoreModule,
    CcValidationErrorsModule,
    NxHeadlineModule,
    NxGridModule,
    NxFormfieldModule,
    NxInputModule,
    NxDynamicTableModule,
    NxSwipebarModule,
    NxTableModule
  ],
  exports: [EcPriceSummaryComponent]
})
export class EcPriceSummaryModule {}
