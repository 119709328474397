import { NgModule } from '@angular/core';
import {
  BFF_BASE_URL_TOKEN,
  PAGES_CONFIGURATION,
  CHANNEL_TOKEN,
  CHANNEL,
  EXPERIMENTAL_NEXT_BUTTON_ALWAYS_ENABLED
} from '@allianz/taly-core';

import { envManager } from '../environment-manager';
import POLICY from '!raw-loader!../config/policy.txt';
import {
  ACL_POLICY_CONTENT_TOKEN,
  AclModule,
  AclService,
  AclInspectorService
} from '@allianz/taly-acl/angular';
import { TalyFrameModule } from '@allianz/taly-common/frame';

import { TalyPluginsModule } from './taly-plugins.module';


import pagesConfig from '../config/pages.json';
import { FrameDataModule } from './frame-data.module';
@NgModule({
  exports: [
    AclModule,
    TalyFrameModule,
    TalyPluginsModule
  ],
  declarations: [],
  providers: [
    AclService,
    AclInspectorService,
    { provide: BFF_BASE_URL_TOKEN, useValue: envManager.envData.BFF_BASE_URL },
    {
      provide: ACL_POLICY_CONTENT_TOKEN,
      useValue: POLICY
    },
    {
      provide: PAGES_CONFIGURATION,
      useValue: pagesConfig
    },
    {
      provide: CHANNEL_TOKEN,
      useValue: CHANNEL.EXPERT
    },
    {
      provide: EXPERIMENTAL_NEXT_BUTTON_ALWAYS_ENABLED,
      useValue: false
    }
    
  ],
  imports: [
    FrameDataModule
  ]
})
export class TalyIntegrationModule {}
