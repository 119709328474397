import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipmentFormComponent } from './equipment-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { NxRadioModule } from '@aposin/ng-aquila/radio-button';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxModalModule } from '@aposin/ng-aquila/modal';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxIconModule } from '@aposin/ng-aquila/icon';

@NgModule({
  declarations: [EquipmentFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NxFormfieldModule,
    NxInputModule,
    NxRadioModule,
    NxButtonModule,
    NxModalModule,
    NxHeadlineModule,
    NxIconModule
  ]
})
export class EquipmentFormModule {}
