<div class="centered-content nx-margin-bottom-m " nxModalContent>
  <h2 nxHeadline="subsection-medium" class="nx-margin-bottom-3m" i18n="Change Equipment Type"><b>Change Equipment Type</b></h2>
  <nx-message
    nxContext="warning"
    i18n
  >
    After changing the type data will be reset.
  </nx-message>
  <div>
    <ng-container *ngIf="formGroup">
      <form (ngSubmit)="onSubmit()" [formGroup]="formGroup">

        <div *ngFor="let item of getAvailableConfig" class="form-row">

          <ng-container [ngSwitch]="item.controlType">
            <nx-formfield *ngSwitchCase="'number'" i18n-nxLabel [nxLabel]="item.label ?? item.key">
              <input [formControlName]="item.key" nxInput value="" type="number"/>
              <span *ngIf="item.suffix" nxFormfieldSuffix>{{ item.suffix }}</span>
            </nx-formfield>

            <nx-formfield *ngSwitchCase="'text'" i18n-nxLabel [nxLabel]="item.label ?? item.key">
              <input [formControlName]="item.key" nxInput value="" type="text"/>
              <span *ngIf="item.suffix" nxFormfieldSuffix>{{ item.suffix }}</span>
            </nx-formfield>

            <nx-radio-group *ngSwitchCase="'radio'" [name]="item.key" [formControlName]="item.key" (nxGroupValueChange)="onControlChange(item.key, $event.value)">
              <nx-label class="nx-margin-bottom-m">{{ item.label ?? item.key }}</nx-label>
              <div class="horizontal-buttons">
                <nx-radio *ngFor="let opt of item.options" [nxValue]="opt.value"
                          class="nx-margin-right-m">{{opt.label}}</nx-radio>
              </div>
            </nx-radio-group>

            <ng-container *ngSwitchCase="'crud'">
              <ng-container *ngIf="canAddChild">
                <div>
                  <ng-container *ngFor="let pole of item.options">
                    <button nxButton="secondary small" class="nx-margin-right-2xs nx-margin-bottom-2xs"
                            (click)="addRow(pole)">
                      <nx-icon name="plus" class="nx-margin-right-2xs"></nx-icon>
                      {{ pole.label }}
                    </button>
                  </ng-container>
                </div>
              </ng-container>

              <div>
                <ng-container *ngFor="let pole of deleteButtons">
                  <button nxButton="secondary danger small" class="nx-margin-right-2xs nx-margin-bottom-2xs"
                          (click)="removeRow(pole)">
                    <nx-icon name="trash" class="nx-margin-right-2xs"></nx-icon>
                    Delete {{ pole.label }}
                  </button>
                </ng-container>
              </div>


            </ng-container>

          </ng-container>

        </div>

        <!--      <div class="form-row">-->
        <!--        <button nxButton="primary" type="submit" [disabled]="!formGroup.valid">Save</button>-->
        <!--      </div>-->
      </form>
    </ng-container>
  </div>
</div>

<div nxModalActions>

  <button nxButton="secondary small-medium" (click)="cancel.emit()" class="small secondary" i18n>Cancel</button>
  <button nxButton="primary small-medium" class="nx-margin-left-s small" (click)="submit()"
          [disabled]="!isFormValid()" i18n>Submit
  </button>
</div>
