import { DoublePole } from './double-pole';
import { BoxContainer } from '../containers/box-container';
import { EcComponent } from './ecComponent';
import { AvailableConfigType, InputFieldConfig } from './base-component';
import { Authority } from '../authorities/authority';
import { Ampere } from '../core/unit.model';
import { ExistingEquipment } from '../equipments/existing-equipment';

export class Mcb2Pole extends DoublePole {
  isOverCurrentProtection = false;

  _availableSize = [16, 20, 25, 32, 40, 50, 63, 80, 100, 125];

  _name = `MCB 2P`;

  isPoleCanAddAllDevices?: boolean;

  get isOverCurrent(): boolean {
    const isOverCurrent = super.isOverCurrent;
    if (isOverCurrent) {
      this.getWires().forEach((w) => {
        w.isExisting = !isOverCurrent;
        w._isLock = !isOverCurrent;
      });
    }

    return isOverCurrent;
  }

  setBoxContainer(boxContainer: BoxContainer) {
    if (boxContainer) {
      boxContainer.description += `${boxContainer.name} ${this.name}`;
      boxContainer.mainInsider = this;
      this.container = boxContainer;
    } else {
      const consumerBox = new BoxContainer();
      this.container = consumerBox;
    }
    if (this._container) {
      this._container.add(this);
    }
  }

  add(component: EcComponent) {
    super.add(component);
  }

  getAvailableConfig(): AvailableConfigType {
    let options: InputFieldConfig['options'] = [];

    const parentCurrentSize = this.parent?.currentSize;

    if (parentCurrentSize) {
      options = this.availableSize
        /**
         *  Multiply * 1.3 เพื่อเป็นค่าความคลาดเคลี่ยน
         */
        .filter(
          (availableSize) =>
            availableSize <= parentCurrentSize * (parentCurrentSize <= 45 ? 1.3 : 1)
        )
        .map((data) => ({
          value: data,
          label: data
        }));

      return [
        {
          key: 'currentSize',
          value: this.currentSize,
          controlType: 'radio',
          label: `Size (A)`,
          suffix: 'Ampere',
          options
        }
      ];
    }

    return [
      {
        key: 'currentSize',
        value: this.currentSize,
        controlType: 'radio',
        label: $localize`Please select properly meter size first`,
        suffix: 'Ampere'
      }
    ];
  }

  findAuthority(c: EcComponent | null): EcComponent | null {
    if (c instanceof Authority) {
      return c;
    }
    if (c) return this.findAuthority(c.getParent());
    else return null;
  }

  set currentSize(value: Ampere) {
    super.currentSize = value;
  }

  get currentSize(): Ampere | undefined {
    if (this._currentSize) {
      return this._currentSize;
    }
    if (this.isExisting) {
      return this._currentSize;
    }
    if (this.size === 0) {
      return undefined;
    }
    return this.findAvailableSize();
  }

  get current(): Ampere {
    if (this._current) {
      return this._current;
    }

    const existingPoles = this.children.filter(
      (c) =>
        c.current > 0 &&
        (c.getChildren().length === 0 ||
          c.getChildren().some((child) => child instanceof ExistingEquipment))
    );

    const sumExistingPoles = existingPoles.reduce((acc, c) => acc + c.current, 0);
    const newPoles = this.children.filter(
      (c) =>
        c.getChildren().length > 0 &&
        !c.getChildren().some((child) => child instanceof ExistingEquipment)
    );
    const sumNewPoles = newPoles.reduce((acc, c) => acc + c.current, 0);

    const currentFromExistingPoles = Math.min(sumExistingPoles, this._currentSize ?? Infinity);

    return currentFromExistingPoles + sumNewPoles;
  }
}
