import { Wire } from './wire';
import { FlatJson } from '../entities/ecComponent';
import { GroundBar } from '../containers/ground-bar';
import { GROUND_SIZE_MAPPER, MAIN_GROUND_WIRE_MAPPER, POLE_SIZE } from './wire.const';

export class GroundWire extends Wire {
  // minimumSize = 10;

  toleranceFactorSize = 1.25;
  _name = 'Ground Wire';

  _shortName = 'G';

  sizeMapper = GROUND_SIZE_MAPPER;

  get type() {
    return 'Wire';
  }

  getFlatJson(): FlatJson {
    const json = super.getFlatJson();
    return {
      ...json,
      input: this.output.id,
      output: this.input.id
    };
  }

  get size(): number | undefined {
    if (this._size !== undefined) {
      return this._size;
    }
    if (this.input.type === 'GroundBar') {
      this.toleranceFactorSize = 1;
      this.minimumSize = 10;
      return 10;
    }

    return super.size;
  }

  set size(value: number | undefined) {
    super.size = value;
  }
}
