import { ConsumerBox } from '../../containers/consumer-box';
import { Meter } from '../../entities/meter';
import { EcComponent } from '../../entities/ecComponent';
import { EvCharger } from '../../equipments/ev-charger';
import { Pole } from '../../entities/pole';
import { EvChargerInstall } from './ev-charger-install.director';
import { AirConditioner } from '../../equipments/air-conditioner';
import { WaterHeater } from '../../equipments/water-heater';
import { Lighting } from '../../equipments/lighting';
import { LightingChild } from '../../equipments/lighting-child';
import { PowerSocket } from '../../equipments/power-socket';

export class HouseConstructionDirector extends EvChargerInstall {
  targetConsumerBox!: ConsumerBox;

  isSkipConfigExistingDevice = true;

  isPoleCanAddAllDevices = true;

  initialForConfigExistingDevice(): void {}

  initialForConfigNewDevice() {
    const meter = new Meter();
    meter.isExisting = false;
    meter.canConfigForNewDevice = true;

    const MCB = this.getNewMainDoublePole();
    MCB.isExisting = false;
    MCB.canConfigForNewDevice = true;
    MCB.isPoleCanAddAllDevices = this.isPoleCanAddAllDevices;

    const consumerBox = new ConsumerBox();
    consumerBox.isExisting = false;
    consumerBox.add(MCB);
    consumerBox.mainInsider = MCB;
    consumerBox.socketNumber = 4;
    this.targetConsumerBox = consumerBox;

    meter.add(MCB);

    this.builder.addComponentToRoot(meter);
  }

  addElectricEquipment(component: EcComponent) {
    component.isExisting = false;
    if (component instanceof EvCharger) {
      this.getRcd2Pole(component);
      return;
    }

    this.targetConsumerBox.connectPole(component);
  }

  removeElectricEquipment(name: string) {
    const component = this.traversal.findByName(name);
    if (!component) {
      console.warn(`Can not find ${name}`);
      return;
    }
    this.traversal.findUp(component, (c: EcComponent) => {
      if (c instanceof Pole) {
        c.remove(component);
      }
    });
  }

  testing() {
    const air = new AirConditioner(`AirConditioner 1`);
    air.BTU = 12000;
    this.addElectricEquipment(air);

    const waterHeater = new WaterHeater(`WaterHeater 1`, 3000);
    this.addElectricEquipment(waterHeater);

    const evCharger = new EvCharger(`EvCharger 1`, 7400);
    this.addElectricEquipment(evCharger);

    const evCharger2 = new EvCharger(`EvCharger 2`, 3400);
    this.addElectricEquipment(evCharger2);

    this.removeElectricEquipment(`EvCharger 1`);
    this.removeElectricEquipment(`AirConditioner 1`);

    const lighting = new Lighting(`Lighting 1`);
    const fluorescent = new LightingChild('Fluorescent');
    fluorescent.power = 100;
    fluorescent.amount = 3;
    const LED = new LightingChild('LED');
    LED.power = 200;
    LED.amount = 2;
    const bulb = new LightingChild('BLUB');
    bulb.power = 300;
    bulb.amount = 1;
    lighting.addList([fluorescent, LED, bulb]);
    this.addElectricEquipment(lighting);

    const powerSocket = new PowerSocket();
    powerSocket.numberOfSocket = 5;
    this.addElectricEquipment(powerSocket);
  }

  initialForConfigNewDeviceStep1(): void {}
}
