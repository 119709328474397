import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  AbstractBuildingBlock,
  applyValidationConfig,
  createBuildingBlockProvider,
  ValidationConfig,
  ValidationConfigItem
} from '@allianz/taly-core';
import { FormGroupTyped } from '@allianz/taly-common';
import { autoFormBindingFactory, FormBindingReturnValue } from '@allianz/taly-acl/form-support';
import {
  EcCalculatePriceForm,
  EcCalculatePriceResources,
  EcCalculatePriceState,
  Order
} from './ec-calculate-price.model';
import {
  EcMdbManagerState,
  InstallType,
  Pole,
  PoleType
} from '../ec-mdb-manager/ec-mdb-manager.model';
import { LocalizeFn } from '@angular/localize/init';

declare let $localize: LocalizeFn;

@UntilDestroy()
@Component({
  selector: 'bb-ec-calculate-price',
  templateUrl: 'ec-calculate-price.component.html',
  styleUrls: ['ec-calculate-price.component.scss'],
  providers: [createBuildingBlockProvider(EcCalculatePriceComponent)]
})
export class EcCalculatePriceComponent
  extends AbstractBuildingBlock<EcCalculatePriceState, EcCalculatePriceResources>
  implements OnInit
{
  form = new FormGroup({
    name: new FormControl('EvCharger 1'),
    type: new FormControl('Wire'),
    amount: new FormControl(20, [Validators.required]),
    price: new FormControl(250.0)
  }) as FormGroupTyped<EcCalculatePriceForm>;

  aclFormBinding?: FormBindingReturnValue;

  validationMap?: Map<string, ValidationConfig[]>;

  stateBB: EcCalculatePriceState = { orders: [], sumPrice: 0 };
  resourcesBB: EcCalculatePriceResources | undefined;

  formArray = new FormArray([]);

  constructor(injector: Injector, private cdr: ChangeDetectorRef) {
    super(injector);
  }

  override ngOnInit(): void {
    this.setupFormChangesSubscriptions();
    this.setupAclBinding();
  }

  private setupAclBinding(): void {
    this.aclFormBinding = autoFormBindingFactory()(this.acl, this.form);
    this.aclFormBinding.stream$.pipe(untilDestroyed(this)).subscribe();
  }

  private setupFormChangesSubscriptions(): void {
    this.formArray.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.stateChanged());

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.stateChanged());

    this.form.statusChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(() => this.checkCompletion());
  }

  private checkCompletion(): void {
    if (this.form.valid || this.form.disabled) {
      this.commitCompletion();
    } else {
      this.revertCompletion();
    }
  }

  override setValidationConfiguration(data: ValidationConfigItem[]): void {
    this.validationMap = applyValidationConfig(this.form, data);
    this.cdr.markForCheck();
  }

  override setResources(data: EcCalculatePriceResources): void {
    this.resourcesBB = data;
    if (this.resourcesBB.calculatorType === 'EV_CHARGER') {
      const allEvChargers = this.resourcesBB.mdbManager.table.filter(
        (pole) => pole.type === 'EV_CHARGER'
      );
      if (
        this.resourcesBB.installType === 'NEW_MCB' ||
        this.resourcesBB.installType === 'NEW_METER'
      ) {
        if (allEvChargers.length === 1) {
          this.addMainBoxToForm({
            name: $localize`Steel Box`
          });
        } else if (allEvChargers.length > 1) {
          this.addMainBoxToForm({
            name: $localize`Consumer Box`
          });
        }
      }
      // this.addMCBEvCharger(this.resourcesBB.installType, this.resourcesBB.mdbManager);
      this.addEvChargers(allEvChargers);
    } else if (this.resourcesBB.calculatorType === 'HOUSE_CONSTRUCTION') {
    } else if (this.resourcesBB.calculatorType === 'MAINTENANCE') {
      this.addGroundWithClamp({ parent: `MCB`, category: 'MCB' });
      for (const pole of this.resourcesBB.mdbManager.table) {
        this.addGroundWithClamp({ parent: pole.name ?? '', type: pole.type });
      }
    }
  }

  override setState(data: EcCalculatePriceState): void {
    this.stateBB = data;
  }

  override getState(): EcCalculatePriceState {
    const products: Order[] = this.formArray.value.map((product: Order) => ({
      name: product.name,
      type: product.type,
      amount: product.amount,
      price: product.amount * 2
    }));
    const sumPrice = products.reduce((prev, current) => prev + current.price, 0);
    return {
      orders: products,
      sumPrice
    };
  }

  override getForm(): FormGroupTyped<EcCalculatePriceForm> {
    return this.form;
  }

  override onPageConnection(): void {
    console.log('The Building Block EcCalculatePrice got connected to the page');
    this.checkCompletion();
  }

  addRow() {
    this.formArray.push(
      new FormGroup({
        amount: new FormControl(0, [Validators.required]),
        type: new FormControl('')
      })
    );
  }

  removeRow(index: number) {
    this.formArray.removeAt(index);
  }

  getFormGroup(i: number): FormGroup {
    return this.formArray.controls[i] as FormGroup;
  }

  addGroundWithClamp(data: { parent: string; type?: PoleType; category?: string }) {
    this.addEquipmentToForm({
      name: $localize`Ground Wire`,
      type: $localize`Wire`,
      parent: data.parent,
      category: $localize`Wire`,
      amount: 20
    });

    if (data.type === 'EV_CHARGER' || data.category === 'MCB') {
      this.addEquipmentToForm({
        name: $localize`Clamp`,
        type: $localize`Equipment`,
        parent: data.parent,
        category: $localize`Equipment`
      });

      this.addEquipmentToForm({
        name: $localize`Ground rod`,
        type: $localize`Equipment`,
        parent: data.parent,
        category: $localize`Equipment`
      });
    }
  }

  addRCDToForm(data: { name: string; parent: string }) {
    this.addEquipmentToForm({
      name: data.name,
      type: $localize`RCD`,
      parent: data.parent,
      category: $localize`Equipment`
    });
    this.addEquipmentToForm({
      name: $localize`Steel Box`,
      type: $localize`Steel Box`,
      parent: data.parent,
      category: $localize`Equipment`
    });
  }

  addMainBoxToForm(data: { name: string; parent?: string }) {
    this.addEquipmentToForm(data);

    this.addEquipmentToForm({
      name: $localize`MCB 2 Pole`,
      parent: data.parent
    });
  }

  addEquipmentToForm(data: Partial<Order>) {
    this.formArray.push(
      new FormGroup({
        name: new FormControl(data.name),
        type: new FormControl(data.type),
        parent: new FormControl(data.parent),
        category: new FormControl(data.category),
        amount: new FormControl(data.amount ?? 1, [Validators.required])
      })
    );
  }

  private addEvChargers(evChargers: Pole[]) {
    for (const evCharger of evChargers) {
      const parentName = $localize`${evCharger.name} (${evCharger.power} Watt)`;
      this.addEquipmentToForm({
        name: parentName,
        type: $localize`EvCharger`,
        category: $localize`Equipment`
      });

      if (evChargers.length > 1) {
        this.addEquipmentToForm({
          name: $localize`1 Pole`,
          type: $localize`Pole`,
          parent: parentName,
          category: $localize`Equipment`
        });
      }

      this.addRCDToForm({ name: $localize`RCD`, parent: parentName });

      this.addEquipmentToForm({
        name: $localize`Neutral & Line Wire`,
        type: $localize`Wire`,
        parent: parentName,
        category: $localize`Wire`,
        amount: 20
      });

      this.addEquipmentToForm({
        name: $localize`Ground Wire`,
        type: $localize`Wire`,
        parent: parentName,
        category: $localize`Wire`,
        amount: 20
      });

      this.addEquipmentToForm({
        name: $localize`Clamp`,
        type: $localize`Equipment`,
        parent: parentName,
        category: $localize`Equipment`
      });

      this.addEquipmentToForm({
        name: $localize`Ground rod`,
        type: $localize`Equipment`,
        parent: parentName,
        category: $localize`Equipment`
      });
    }
  }

  private addMCBEvCharger(installType: InstallType, mdbManager: EcMdbManagerState) {
    if (installType === 'INCREASE_MCB_SIZE') {
    }
    if (installType === 'NEW_MCB' || installType === 'NEW_METER') {
      const mcbName = $localize`MCB (${mdbManager.MCBSize} A)`;
      this.addEquipmentToForm({
        name: mcbName,
        type: $localize`MCB`,
        parent: ``,
        category: $localize`Equipment`
      });

      this.addRCDToForm({ name: $localize`RCD (${mdbManager.MCBSize} A)`, parent: mcbName });

      this.addEquipmentToForm({
        name: $localize`Neutral & Line Wire`,
        type: $localize`Wire`,
        parent: mcbName,
        category: $localize`Wire`,
        amount: 20
      });

      this.addGroundWithClamp({ parent: mcbName, type: 'EV_CHARGER' });
    }
  }
}
