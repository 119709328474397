import { STORE_CLASS } from './store-class';

export class DynamicClass {
  constructor(className: string, opts: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (STORE_CLASS[className] === undefined || STORE_CLASS[className] === null) {
      throw new Error(`Class type of \'${className}\' is not in the store`);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const dynamicClass = new STORE_CLASS[className]();
    for (const [key, val] of Object.entries(opts)) {
      if (key === 'children' || key === 'wires') {
        continue;
      }
      // eslint-disable-next-line no-prototype-builtins
      if (dynamicClass.hasOwnProperty(key)) {
        dynamicClass[key] = val;
      }
      // eslint-disable-next-line no-prototype-builtins
      if (dynamicClass.hasOwnProperty(`_${key}`)) {
        dynamicClass[`_${key}`] = val;
      }
    }
    return dynamicClass;
  }
}
