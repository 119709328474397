<h2 i18n>Setting {{ data.label }} </h2>
<ng-container *ngIf="formGroup && data.type">
  <form
    [formGroup]="formGroup"
    class=""
  >
    <nx-formfield i18n-nxLabel nxLabel="Name (Optionanl)">
      <input formControlName="name" nxInput value=""/>
    </nx-formfield>


    <ng-container *ngIf="!isPoleCurrentMode">

      <ng-container *ngIf="data.type === 'WATER_HEATER'">
        <nx-formfield i18n-nxLabel nxLabel="Power">
          <input formControlName="power" nxInput placeholder="3700" value="" type="number"/>
          <span nxFormfieldSuffix>Watt</span>
        </nx-formfield>
      </ng-container>

      <ng-container *ngIf="data.type === 'WATER_PUMP'">
        <nx-formfield i18n-nxLabel nxLabel="Power">
          <input formControlName="power" nxInput placeholder="3700" value="" type="number"/>
          <span nxFormfieldSuffix>Watt</span>
        </nx-formfield>
      </ng-container>

      <ng-container *ngIf="data.type === 'EV_CHARGER'">
        <nx-radio-group formControlName="power" name="myGroup">
          <nx-label class="nx-margin-bottom-m">Power</nx-label>
          <div class="horizontal-buttons">
            <nx-radio nxValue="3700" class="nx-margin-bottom-m">3.7 kWatt</nx-radio>
            <nx-radio nxValue="7400" class="nx-margin-left-s nx-margin-bottom-m">7.4 kWatt</nx-radio>
          </div>
        </nx-radio-group>
      </ng-container>

      <ng-container *ngIf="data.type === 'AIR_CONDITIONER'">
        <nx-formfield i18n-nxLabel nxLabel="BTU">
          <input formControlName="BTU" nxInput placeholder="12000" type="number"/>
          <span nxFormfieldSuffix>BTU</span>
        </nx-formfield>
      </ng-container>

      <ng-container *ngIf="data.type === 'POWER_SOCKET'">
        <nx-formfield  i18n-nxLabel nxLabel="Number of socket">
          <input formControlName="numberOfSocket" nxInput placeholder="5" type="number" [min]="1" [max]="5"/>
          <span nxFormfieldSuffix></span>
          <span nxFormfieldHint> Not over than 5 </span>
        </nx-formfield>
      </ng-container>

      <ng-container *ngIf="data.type === 'LIGHTING'">

        <div class="scroll-container">
          <table nxTable condensed class="nx-margin-bottom-xs">
            <thead>
            <tr nxTableRow>
              <th nxHeaderCell i18n>Type</th>
              <th nxHeaderCell i18n>Power (Watt)</th>
              <th nxHeaderCell i18n>Amount</th>
              <th nxHeaderCell></th>
            </tr>
            </thead>
            <tbody>
            <tr
              nxTableRow
              *ngFor="let control of formArray.controls; index as i"
              [formGroup]="getFormGroup(i)"
            >
              <td nxTableCell class="status-cell">
                <nx-formfield>
                  <nx-dropdown formControlName="type">
                    <nx-dropdown-item nxValue=""></nx-dropdown-item>
                    <nx-dropdown-item
                      nxValue="LED"
                    ></nx-dropdown-item>
                    <nx-dropdown-item
                      nxValue="Fluorescent"
                    ></nx-dropdown-item>
                    <nx-dropdown-item
                      nxValue="Light bulb"
                    ></nx-dropdown-item>
                  </nx-dropdown>
                </nx-formfield>
              </td>
              <td nxTableCell>
                <nx-formfield>
                  <input nxInput formControlName="power" type="number"/>
                </nx-formfield>
              </td>
              <td nxTableCell>
                <nx-formfield>
                  <input nxInput formControlName="amount" type="number"/>
                </nx-formfield>
              </td>
              <td nxTableCell>
                <button
                  nxPlainButton=""
                  (click)="removeRow(i)"
                  aria-label="Remove row"
                >
                  <nx-icon name="trash-o"></nx-icon>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <button nxPlainButton (click)="addLightingRow()">
          <nx-icon name="plus" class="nx-margin-right-2xs"></nx-icon>
          Add row
        </button>

      </ng-container>

      <nx-formfield i18n-nxLabel nxLabel="Power" class="nx-margin-top-s">
        <input nxInput readonly [value]="(state$ | async).power"/>
        <span nxFormfieldSuffix>Watt</span>
      </nx-formfield>

      <nx-formfield i18n-nxLabel nxLabel="Current">
        <input nxInput readonly [value]="(state$ | async).current"/>
        <span nxFormfieldSuffix>Ampere</span>
      </nx-formfield>

<!--      <nx-formfield i18n-nxLabel nxLabel="MCB Size">-->
<!--        <input nxInput readonly [value]="(state$ | async).poleSize"/>-->
<!--        <span nxFormfieldSuffix>Ampere</span>-->
<!--      </nx-formfield>-->

    </ng-container>

<!--    <ng-container *ngIf="isPoleCurrentMode">-->

<!--      <nx-radio-group formControlName="poleSize" name="myGroup">-->
<!--        <nx-label class="nx-margin-bottom-m" i18n>MCB Current</nx-label>-->
<!--        <div class="horizontal-buttons">-->
<!--          <nx-radio *ngFor="let current of data.poleSizeOptions" [nxValue]="current" class="nx-margin-left-s nx-margin-bottom-m">-->
<!--            {{ current }} A-->
<!--          </nx-radio>-->
<!--        </div>-->
<!--      </nx-radio-group>-->
<!--    </ng-container>-->

  </form>
</ng-container>

<div class="nx-margin-bottom-2m">

  <ng-container *ngFor="let message of errorMessage | keyvalue">
    <nx-message  nxContext="error">
      {{ message.value }}
    </nx-message>
  </ng-container>

</div>

<div nxModalActions style=";display:flex;justify-content: flex-end;">
  <button nxButton="secondary small-medium" (click)="cancel.emit()" i18n>Cancel</button>
  <button nxButton="primary small-medium" class="nx-margin-left-s" (click)="submit()"
          [disabled]="!isFormValid()" i18n>Submit
  </button>
</div>



