import { Wire } from '../wires/wire';
import { BaseComponent } from './base-component';
import { BoxContainer } from '../containers/box-container';
import { Ampere } from '../core/unit.model';

export interface FlatJson {
  id: string;
  name: string;
  shortName?: string;
  className: string;
  wires: string[];
  container: string;
  type: string;
  parentId?: string;
  description?: string;
  currentSize?: number;
  current: number;
  power: number;
  voltage: number;
  isExisting: boolean;
  isError: boolean;
  isCompletedState: boolean;
  isOverCurrent: boolean;
  canConfig: boolean;
  canAdd: boolean;
  children: string[];
  tags: string[];
  texts: string[];
  input?: string;
  output?: string;
  mainInsider?: unknown;
  poles?: unknown[];
  socketNumber?: number;
  length?: number;
  tooltip?: string;
  size?: number;
  canChangeEquipment?: boolean;
}

export abstract class EcComponent extends BaseComponent {
  public isEnabledForceSetCurrent = false;

  public isMainBreaker: boolean = false;

  protected parent!: EcComponent | null;
  protected _container!: BoxContainer | null;

  /**
   * Wire to parent
   * @protected
   */
  protected wires: Wire[] = [];

  get isCompletedState(): boolean {
    if (this.isError) {
      return false;
    }
    return !(this.isExisting && this.currentSize === undefined);
  }

  /**
   * Optionally, the base Component can declare an interface for setting and
   * accessing a parent of the component in a tree structure. It can also
   * provide some default implementation for these methods.
   */
  public setParent(parent: EcComponent | null) {
    this.parent = parent;
    if (parent) {
      this.voltage = parent.voltage;
      this.setWires(this.getWireForParent());
    } else {
      this.wires = [];
    }
  }

  get container() {
    return this._container;
  }

  set container(container: BoxContainer | null) {
    this._container = container;
  }

  get isConfigNewMode(): boolean {
    return this._isConfigNewMode;
  }

  set isConfigNewMode(value: boolean) {
    this._isConfigNewMode = value;
    this.getWires().forEach((w) => (w.isConfigNewMode = value));
  }

  public getParent(): EcComponent | null {
    return this.parent;
  }

  public addWire(wire: Wire) {
    this.wires.push(wire);
    if (this.parent) {
      wire.setComponents(this, this.parent);
      wire.isExisting = this.isExisting;
    }
    wire.mainDevice = this.mainDevice;
  }

  public connectWireWith(wire: Wire, component: EcComponent) {
    this.wires.push(wire);
    wire.setComponents(this, component);
  }

  public addWires(wires: Wire[]) {
    wires.forEach((w) => this.addWire(w));
  }

  public setWires(wires: Wire[]) {
    this.wires = [];
    this.addWires(wires);
  }

  public getWires(): Wire[] {
    return this.wires;
  }

  /**
   * In some cases, it would be beneficial to define the child-management
   * operations right in the base Component class. This way, you won't need to
   * expose any concrete component classes to the client code, even during the
   * object tree assembly. The downside is that these methods will be empty
   * for the leaf-level components.
   */
  public add(component: EcComponent): void {}

  public remove(component: EcComponent): void {}

  /**
   * You can provide a method that lets the client code figure out whether a
   * component can bear children.
   */
  public isComposite(): boolean {
    return false;
  }

  public getChildren(): EcComponent[] {
    return [];
  }

  setChildren(children: EcComponent[]) {}

  public getRecursiveName(): any {
    return '';
  }

  public forceSetCurrent(currentSize: Ampere) {
    if (this.isEnabledForceSetCurrent) {
      if (this.type === 'Mcb2Pole' || this.type === 'Pole') {
        this._current = currentSize;
        return;
      }
      this._current = (currentSize * 0.8) / 1.25;
      return;
    }
  }

  public getJsonData(): any {
    return {
      ...super.getJsonData(),
      wires: this.getWires().map((c) => c.getJsonData()),
      children: this.getChildren().map((c) => c.getJsonData())
    };
  }

  getFlatJson(): FlatJson {
    const tags: string[] = [];
    if (!this.isCompletedState) {
      tags?.push('notCompleted');
    }
    if (this.isExisting) {
      tags.push('existing');
    } else {
      tags.push('new');
    }
    if (this.isOverCurrent) {
      tags.push('warning');
    }
    if (this.isError) {
      tags.push('danger');
    }

    const texts: string[] = [];

    texts.push(`current: ${this.current.toFixed(2)} A`);

    return {
      id: this.id,
      name: this.name,
      className: this.className,
      type: this.type,
      wires: this.getWires().map((w) => w.id),
      container: this.container?.id,
      parentId: this.getParent()?.id,
      description: this.description,
      current: this.current,
      power: this.power,
      voltage: this.voltage,
      currentSize: this.currentSize,
      isExisting: this.isExisting,
      isError: this.isError,
      isCompletedState: this.isCompletedState,
      isOverCurrent: this.isOverCurrent,
      canConfig: this.canConfig,
      canAdd: this.canAdd,
      canChangeEquipment: this.canChangeEquipment,
      children: this.getChildren().map((c) => c.id),
      size: this.size,
      tags,
      texts
    };
  }

  protected abstract getWireForParent(): Wire[];
}
