import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AbstractBuildingBlock, createBuildingBlockProvider } from '@allianz/taly-core';
import {
  EcPriceSummaryResources,
  EcPriceSummaryState,
  PriceSummary
} from './ec-price-summary.model';
import { NxDynamicTableColumnDefinition } from '@aposin/ng-aquila/dynamic-table';
import { EcFacadeService } from '../shared/services/ec-facade.service';
import { BoxContainer, EcComponent, Wire } from '@ec-workspace/ec-library';
import { SortDirection, SortEvent } from '@aposin/ng-aquila/table';
import { LocalizeFn } from '@angular/localize/init';

declare let $localize: LocalizeFn;

@UntilDestroy()
@Component({
  selector: 'bb-ec-price-summary',
  templateUrl: 'ec-price-summary.component.html',
  styleUrls: ['ec-price-summary.component.scss'],
  providers: [createBuildingBlockProvider(EcPriceSummaryComponent)]
})
export class EcPriceSummaryComponent
  extends AbstractBuildingBlock<EcPriceSummaryState, EcPriceSummaryResources>
  implements OnInit
{
  resourcesBB?: EcPriceSummaryResources;
  componentTable?: EcComponent[];
  allComponentTable?: (EcComponent | Wire | BoxContainer)[];
  wireTable?: EcComponent[];
  boxContainersTable?: EcComponent[];
  displayedColumnsComponent: NxDynamicTableColumnDefinition[] = [
    { title: '#', key: 'count', type: 'numeric' },
    { title: $localize`Name`, key: 'description', type: 'string' },
    { title: $localize`Type`, key: 'type', type: 'string' },
    { title: $localize`Product`, key: 'productDescription', type: 'string' },
    { title: $localize`Qty`, key: 'quantity', type: 'numeric' },
    { title: $localize`Unit Price`, key: 'unitPrice', type: 'numeric' },
    { title: $localize`Amount`, key: 'amount', type: 'numeric' }
  ];
  priceSummaryTable: PriceSummary[] = [];

  total = 0;

  constructor(
    injector: Injector,
    private cdr: ChangeDetectorRef,
    private ecFacadeService: EcFacadeService
  ) {
    super(injector);
  }

  override ngOnInit(): void {}

  private checkCompletion(): void {
    this.commitCompletion();
  }

  override setResources(data: EcPriceSummaryResources): void {
    console.log('The Building Block EcPriceSummary received the following resources:', data);

    console.table(
      this.ecFacadeService.traversal.getAllComponentsArray().map((x) => x.getFlatJson())
    );

    this.allComponentTable = this.ecFacadeService.traversal
      .getAllComponentsArray()
      .filter((c) => c.shouldBuy);

    console.table(this.allComponentTable.map((x) => x.getFlatJson()));

    const priceSummaryTable: PriceSummary[] = [];

    for (const c of this.allComponentTable) {
      const product = c.findProduct(data.products);
      if (!product) {
        continue;
      }

      priceSummaryTable.push(...c.getPriceSummary(data.products));
    }
    this.priceSummaryTable = priceSummaryTable;
    console.log(this.priceSummaryTable);
    this.total = this.priceSummaryTable.reduce(
      (total, item) => Number(total) + Number(item.amount),
      0
    );
  }

  // getComponentToBuy<T extends FlatJson>(list: Record<string, T>): T[] {
  //   const componentList = Object.values((list))
  //     .filter((c) => c.isOverCurrent || !c.isExisting)
  //     .map(c => {
  //       return {
  //         ...c,
  //         tags: JSON.stringify(c.tags)
  //       }
  //     });
  //   return componentList;
  // }

  override setState(data: EcPriceSummaryState): void {}

  override getState(): EcPriceSummaryState {
    return {};
  }

  override onPageConnection(): void {
    console.log('The Building Block EcPriceSummary got connected to the page');
    this.checkCompletion();
  }

  /**
   * Sorts the table data by a certain category.
   */
  sortTable(sort: SortEvent) {
    this.priceSummaryTable = this.priceSummaryTable.sort(
      (a: { [key: string]: any }, b: { [key: string]: any }) => {
        if (sort.active in a && sort.active in b) {
          return this.compare(a[sort.active], b[sort.active], sort.direction);
        }
        return 0;
      }
    );
  }

  compare(a: number | string | Date, b: number | string | Date, direction: SortDirection) {
    return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
  }
}
