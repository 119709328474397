import { JsonDataType } from '../core/current-container.interface';
import { ElectricalContainer } from '../entities/electrical-container';
import { EcComponent, FlatJson } from '../entities/ecComponent';
import { AvailableConfigType } from '../entities/base-component';
import { Wire } from '../wires/wire';

export class BoxContainer extends ElectricalContainer implements JsonDataType {
  protected _mainInsider!: EcComponent;

  id: string;

  children: EcComponent[] = [];

  constructor(protected _name?: string) {
    super(_name);
  }

  get mainInsider(): EcComponent {
    return this._mainInsider;
  }

  set mainInsider(value: EcComponent) {
    this._mainInsider = value;
  }

  get name() {
    return this._name ?? this.constructor.name;
  }

  set name(value: string) {
    this._name = value;
  }

  add(childComponent: EcComponent): void {
    this.children.push(childComponent);
    childComponent.container = this;
  }

  remove(component: EcComponent): void {
    const componentIndex = this.children.indexOf(component);
    this.children.splice(componentIndex, 1);
    component.container = null;
  }

  uniqueID() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }

  get isCompletedState() {
    return true;
  }

  getAvailableConfig(): AvailableConfigType {
    return [];
  }

  protected getWireForParent(): Wire[] {
    return [];
  }

  getJsonData(): any {
    return {
      ...super.getFlatJson()
    };
  }

  getFlatJson(): FlatJson {
    const json: FlatJson = super.getFlatJson();
    json.tags.push('small');
    json.tags.push('partner');
    json.parentId = this.mainInsider?.id;
    json.texts = [];
    json.mainInsider = this.mainInsider?.getFlatJson();
    json.children = this.children?.map((c) => c.id);
    return json;
  }

  get isError() {
    return false;
  }
}
