import { ElectricalBuilder } from './electrical-builder';
import { EcComponent } from '../../entities/ecComponent';
import { ElectricalTraversal } from '../electrical-traversal';
import { Watt } from '../../core/unit.model';
import { EvCharger } from '../../equipments/ev-charger';
import { Rcd2PoleTypeB } from '../../entities/rcd2-pole-type-b';
import { BoxContainer } from '../../containers/box-container';
import { ResidualCurrentDevice } from '../../entities/residual-current-device';
import { ConsumerBox } from '../../containers/consumer-box';
import { Meter } from '../../entities/meter';
import { Mcb2Pole } from '../../entities/mcb-2-pole';
import { Pole } from '../../entities/pole';
import { Ground } from '../../entities/ground';
import { Rcbo2Pole } from '../../entities/rcbo-2-pole';

export type DoublePoleType = 'Mcb2Pole' | 'Rcbo2Pole' | 'Rcd2PoleTypeB';

export abstract class ElectricalDirector {
  targetConsumerBox!: ConsumerBox;
  targetMeter!: Meter;
  meters: EcComponent[] = [];
  currentComponentForEvCharge!: EcComponent;
  MCB1!: EcComponent;

  doublePoleType: DoublePoleType = 'Mcb2Pole';

  isSkipConfigExistingDevice?: boolean;

  isPoleCanAddAllDevices?: boolean;

  get name(): string {
    return this.constructor.name.toUpperCase();
  }

  protected builder!: ElectricalBuilder;
  protected traversal!: ElectricalTraversal;

  /**
   * The Director works with any builder instance that the client code passes
   * to it. This way, the client code may alter the final type of the newly
   * assembled product.
   */
  public setBuilder(builder: ElectricalBuilder): void {
    this.builder = builder;
    this.traversal = builder.traversal;
  }

  initialForConfigNewDevice() {
    // default step
    this.traversal.getAllComponentsArray().forEach((c) => {
      c.lock();
      c.isConfigNewMode = true;
    });
    this.initialForConfigNewDeviceStep1();
  }

  testing() {
    console.log('No test...');
  }

  testingCompleteAllExising() {
    console.log('No test...');
  }

  getElectricEquipment(component: EcComponent, parent?: EcComponent): EcComponent {
    component.isExisting = false;
    if (component instanceof EvCharger) {
      return this.getTemplateEvChargerComponent(component, parent);
    }
    return component;
  }

  addEvChargerByName(name: string, power: Watt): void {
    const newEvCharger = new EvCharger(name, power, false);
    this.addEvCharger(newEvCharger);
  }

  addEvCharger(evCharger: EvCharger) {
    evCharger.isExisting = false;
    evCharger.mainDevice = evCharger;
    const outputComponent = this.getRcd2Pole(evCharger);
    this.targetConsumerBox.connectPole(outputComponent);
  }

  getTemplateEvChargerComponent(newEvCharger: EvCharger, parent?: EcComponent) {
    newEvCharger.isExisting = false;
    const rcd = this.getRcd2Pole(newEvCharger);
    if (parent instanceof Pole) {
      const groundBar = this.targetConsumerBox.groundBar.cloneForLink();
      groundBar.mainDevice = newEvCharger;
      newEvCharger.add(groundBar);
      return rcd;
    } else if (parent instanceof Meter) {
      newEvCharger.add(new Ground());
      return this.getMCB2Pole(newEvCharger, rcd);
    }
    throw new Error(`Don't what to add`);
  }

  getMCB2Pole(newEvCharger: EvCharger, child: EcComponent) {
    const MCB = new Mcb2Pole();
    MCB.lockConfigForNewDevice = true;
    MCB.description = `${newEvCharger.name}`;
    MCB.isExisting = false;
    MCB.add(child);
    MCB.mainDevice = newEvCharger;

    const boxContainer = new BoxContainer();
    boxContainer.isExisting = false;
    boxContainer.add(MCB);
    boxContainer.mainInsider = MCB;
    return MCB;
  }

  getRcd2Pole(newEvCharger: EvCharger) {
    const newRCD = new Rcd2PoleTypeB();
    newRCD.description = `${newEvCharger.name}`;
    newRCD.isExisting = false;
    newRCD.add(newEvCharger);
    newRCD.canChangeEquipment = false;
    newRCD.mainDevice = newEvCharger;

    const boxContainer = new BoxContainer();
    boxContainer.description = newRCD.name;
    boxContainer.isExisting = false;
    boxContainer.add(newRCD);
    boxContainer.mainInsider = newRCD;

    return newRCD;
  }

  removeEvCharger(name: string) {
    const evCharger = this.traversal.findByName(name);
    this.traversal.findUp(evCharger, (component: EcComponent) => {
      if (component instanceof ResidualCurrentDevice) {
        component.getParent()?.remove(component);
      }
    });
  }

  getNewMainDoublePole() {
    if (this.doublePoleType === 'Mcb2Pole') {
      return new Mcb2Pole();
    }
    if (this.doublePoleType === 'Rcbo2Pole') {
      return new Rcbo2Pole();
    }
    if (this.doublePoleType === 'Rcd2PoleTypeB') {
      return new Rcd2PoleTypeB();
    }
    return new Mcb2Pole();
  }

  abstract initialForConfigExistingDevice(): void;

  abstract initialForConfigNewDeviceStep1(): void;
}
