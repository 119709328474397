import {
  ConsumerBox,
  EcComponent,
  ElectricalContainer,
  ElectricalEquipment,
  ElectricalTraversal,
  EvCharger,
  Pole,
  ResidualCurrentDevice
} from '@ec-workspace/ec-library';

export class ElectricalBuilder {
  constructor(private _root: ElectricalContainer, public traversal: ElectricalTraversal) {}

  get root() {
    return this._root;
  }

  set root(root) {
    this._root = root;
  }

  addComponentTo(child: EcComponent, parent: string) {
    const parentComponent = this.traversal.findByName(parent);
    if (!parentComponent) {
      return;
    }

    if (child instanceof ElectricalEquipment && parentComponent.container instanceof ConsumerBox) {
      const pole = new Pole();
      pole.description = `${pole.name} ${child.name}`;
      parentComponent.add(pole);
      pole.add(child);
      return;
    }

    if (child instanceof EvCharger) {
      const RCD = new ResidualCurrentDevice('RCD');
      RCD.description = `${RCD.name} for ${child.name}`;
      parentComponent.add(RCD);
      RCD.add(child);
      return;
    }

    parentComponent.add(child);
  }

  addComponentToRoot(child: EcComponent) {
    this.root.add(child);
  }

  setTraversal(traversal: ElectricalTraversal) {
    this.traversal = traversal;
  }
}
