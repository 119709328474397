import { ElectricalEquipment } from './electrical-equipment';
import { Ampere } from '../core/unit.model';
import { AvailableConfigType } from '../entities/base-component';

const VA_PER_ONE_SOCKET = 180;

export class PowerSocket extends ElectricalEquipment {
  protected _numberOfSocket = 0;

  get current(): Ampere {
    return this.power / this._voltage;
  }

  get power() {
    return this._numberOfSocket * VA_PER_ONE_SOCKET;
  }

  get numberOfSocket() {
    return this._numberOfSocket;
  }

  set numberOfSocket(value: number) {
    this._numberOfSocket = value;
  }

  getAvailableConfig(): AvailableConfigType {
    return [
      {
        key: 'numberOfSocket',
        value: this.numberOfSocket,
        controlType: 'number',
        min: 1,
        max: 5
      }
    ];
  }
}
