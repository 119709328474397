import { Ampere } from '../core/unit.model';
import { EcComponent, FlatJson } from '../entities/ecComponent';
import { AvailableConfigType, BaseComponent } from '../entities/base-component';
import {
  PriceSummary,
  Product
} from '../../../../ec-bb/src/lib/ec-price-summary/ec-price-summary.model';
import { PowerSocket } from '../equipments/power-socket';
import { Meter } from '../entities/meter';
import { Pole } from '../entities/pole';
import { MAIN_GROUND_WIRE_MAPPER, POLE_SIZE, WIRE_SIZE_MAPPER } from './wire.const';
import { DoublePole } from '../entities/double-pole';
import { Mcb2Pole } from '../entities/mcb-2-pole';

export abstract class Wire extends BaseComponent {
  input!: EcComponent;
  output!: EcComponent;

  length?: number = 20;

  sizeMapper = WIRE_SIZE_MAPPER;

  toleranceFactorSize = 1.25;

  minimumSize?: number;

  compareCurrentDevice?: Pole;

  device?: EcComponent;
  _size: number | undefined;

  isMaintenance: boolean = false;

  get current(): Ampere {
    return this.input?.current;
  }

  setComponents(input: EcComponent, output: EcComponent) {
    this.input = input;
    this.output = output;
    this._current = input.current;
    this.description = `${this.input?.name} to ${this.output?.name}`;
  }

  getAvailableConfig(): AvailableConfigType {
    return [
      {
        key: 'length',
        value: this.length,
        controlType: 'number',
        suffix: `m`
      }
    ];
  }

  getFlatJson(): FlatJson {
    const json = super.getFlatJson();
    return {
      ...json,
      shortName: this.shortName,
      input: this.input.id,
      output: this.output.id,
      length: this.length,
      size: this.shouldBuy ? this.sizeDisplay : undefined
    };
  }

  get sizeDisplay(): string {
    return `${this.size} mm²`;
  }

  get isCompletedState(): boolean {
    if (!this.canConfig) {
      return true;
    }
    return this.length !== undefined;
  }

  get size(): number | undefined {
    if (this._size !== undefined) {
      return this._size;
    }
    return this.findSize(this.sizeMapper);
  }

  set size(value: number | undefined) {
    this._size = value;
  }

  findSize(sizeMapper: { cable: number; in: number }[], isGround = false) {
    // const pole = this.findPole(this.output);
    let sizeCompare;
    // if (pole?.currentSize) {
    //   sizeCompare = pole.currentSize;
    // } else {
    //   sizeCompare = this.current;
    // }
    // console.log(`found pole | ${this.output.name} | ${pole} | ${pole?.size} ${pole?.currentSize}`);

    if (this.mainDevice?.type === 'EvCharger') {
      console.log(`main device is EvCharger ${this.name} ${this.output.name} ${this.input.name}`);
      this.toleranceFactorSize = 1;
    }

    /**
     * ถ้าเป็น เต้ารับ ขั้นต่ำต้องเปน 2.5 sqmm
     */
    if (this.input.type === 'PowerSocket') {
      this.toleranceFactorSize = 1;
      this.minimumSize = 2.5;
    }

    console.log(`Output type ${this.output.type}`);
    if (this.output.type === 'Meter') {
      const meter: Meter = this.output as Meter;
      const doublePole = this.input as Mcb2Pole;
      if (doublePole.isPoleCanAddAllDevices) {
        this.minimumSize = meter.getMinimumWireSize();
        console.log('is meter isPoleCanAddAllDevices', this.minimumSize);
      }
    }

    sizeCompare = this.current * this.toleranceFactorSize;

    let cable;

    if (!this.isMaintenance && (this.className === 'GroundWire' || isGround)) {
      const lineWireSize = this.input.getWires()[0];
      let groudSize;
      for (const mapper of MAIN_GROUND_WIRE_MAPPER) {
        if (mapper.main >= (lineWireSize.size ?? 0)) {
          groudSize = mapper.ground;
          break;
        }
      }
      cable = groudSize;
    } else {
      cable = this.findCable(sizeCompare, sizeMapper);
    }

    /**
     * Minimum size logic
     */
    if (this.minimumSize !== undefined) {
      if ((cable ?? 0) <= this.minimumSize) {
        return this.minimumSize;
      }
    }

    return cable;
  }

  protected findCable(sizeCompare: number, sizeMapper: { cable: number; in: number }[]) {
    let sizeCompare2 = 0;
    for (let s of POLE_SIZE) {
      if (s >= sizeCompare) {
        sizeCompare2 = s;
        break;
      }
    }

    let cable;
    for (let s of sizeMapper) {
      if (s.in >= sizeCompare2) {
        cable = s.cable;
        break;
      }
    }
    return cable;
  }

  findPole(component: EcComponent | null | undefined): EcComponent | null {
    if (!component) {
      return null;
    }
    if (component?.type === 'Pole') {
      console.log('found pole', this.name, component);
      return component;
    } else {
      if (!component.getParent()) {
        return null;
      }
      return this.findPole(component?.getParent());
    }
  }

  get quantity(): number {
    return this.length ?? 1;
  }

  getPriceSummary(products: Product[]): PriceSummary[] {
    return super.getPriceSummary(products).map((ps) => ({
      ...ps,
      quantity: `${ps.quantity} m`
    }));
  }

  getAvailableTypeOptions(): AvailableConfigType | null {
    return null;
  }
}
