import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  AllElectricalEquipmentType,
  AvailableConfigType,
  ConsumerBox,
  DoublePole,
  ElectricalContainer,
  Mcb2Pole
} from '@ec-workspace/ec-library';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { NX_MODAL_DATA, NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { PoleFormComponent, PoleState } from '../pole-form/pole-form.component';
import { EcFacadeService } from '../../services/ec-facade.service';
import { Observable } from 'rxjs';

const MAIN_KEY = 'type';

@Component({
  selector: 'itmp-change-equipment-form',
  templateUrl: './change-equipment-form.component.html',
  styleUrls: ['./change-equipment-form.component.scss']
})
export class ChangeEquipmentFormComponent implements OnInit {
  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter();

  equipment!: AllElectricalEquipmentType;

  formGroup!: FormGroup | null;

  getAvailableConfig!: AvailableConfigType;

  componentDialogRef!: NxModalRef<PoleFormComponent>;
  canAddChild = true;
  deleteButtons: { label: string; value: string }[] = [];

  constructor(
    @Inject(NX_MODAL_DATA) public data: { id: string },
    protected ecFacadeService: EcFacadeService,
    public dialogService: NxDialogService
  ) {
    this.equipment = this.ecFacadeService.traversal.allComponentsObject[this.data.id];
    this.formGroup = this.getFormGroup();
    this.formGroup?.controls[MAIN_KEY].setValue(this.equipment[MAIN_KEY]);
  }

  private getFormGroup() {
    if ('getAvailableTypeOptions' in this.equipment) {
      this.getAvailableConfig = this.equipment.getAvailableTypeOptions() ?? [];
      const formConfig: {
        [key: string]: AbstractControl;
      } = {};

      for (const config of this.getAvailableConfig) {
        if ('getChildren' in this.equipment) {
          this.deleteButtons = this.equipment
            .getChildren()
            .filter((c) => c.isNew)
            .map((c) => ({
              label: c.description ?? c.name,
              value: c.id
            }));
        }

        if (config.key === 'children') {
          continue;
        }
        formConfig[config.key] = new FormControl(
          (this.equipment as any)[config.key],
          Validators.required
        );
      }

      return new FormGroup(formConfig);
    }
    return null;
  }

  ngOnInit(): void {}

  onSubmit() {}

  isFormValid() {
    return this.formGroup?.valid;
  }

  submit() {
    if (this.isFormValid()) {
      const deviceToChange = this.formGroup?.controls[MAIN_KEY].value;
      if (this.equipment.type === deviceToChange) {
        console.log('No thing to change');
        this.save.emit();
        return;
      }
      console.log(`deviceToChange`, deviceToChange);

      this.equipment.name = deviceToChange;
      this.equipment.type = deviceToChange;
      if (this.equipment instanceof DoublePole) {
        this.equipment.isOverCurrentProtection = !(this.equipment.type === 'Mcb2Pole');
        if (this.equipment.container instanceof ConsumerBox) {
          this.equipment.container.socketNumber = 4;
        }
      }

      this.ecFacadeService.traversal.recursive();
      //
      // console.log('Change device', this.ecFacadeService.currentEcEquipmentConfigureResources);
      //
      // this.ecFacadeService.director.doublePoleType = deviceToChange;
      //
      // /**
      //  * isSkip = config new device
      //  */
      // if (this.ecFacadeService.currentEcEquipmentConfigureResources.isSkipConfigExistingDevice) {
      //   this.ecFacadeService.startOverProcessNewDevice$.next({
      //     ...this.ecFacadeService.currentEcEquipmentConfigureResources,
      //     mainDoublePoleType: deviceToChange
      //   });
      // } else {
      //   this.ecFacadeService.startOverProcess$.next({
      //     ...this.ecFacadeService.currentEcEquipmentConfigureResources,
      //     mainDoublePoleType: deviceToChange
      //   });
      // }
      //
      this.equipment.afterSubmit();
      this.save.emit();
    }
  }

  addRow(param: { value: string; label: string }) {
    if (param.value === 'CONSUMER_BOX') {
      const mcb = new Mcb2Pole();
      const newConsumerBox = new ConsumerBox(`Consumer Box 1`);
      newConsumerBox.isExisting = false;
      newConsumerBox.socketNumber = 4;
      newConsumerBox.add(mcb);
      newConsumerBox.mainInsider = mcb;

      this.ecFacadeService.director.targetConsumerBox = newConsumerBox;

      this.addChild(mcb);
      this.ecFacadeService.traversal.recursive();
      this.save.emit();

      return;
    }
    this.openFromComponent({
      type: param.value,
      label: param.label,
      voltage: this.ecFacadeService.root?.voltage
    });
  }

  openFromComponent(data: object): Observable<PoleState> {
    this.componentDialogRef = this.dialogService.open(PoleFormComponent, {
      showCloseIcon: true,
      data: data
    });
    this.componentDialogRef.componentInstance.cancel.subscribe(() => {
      this.componentDialogRef.close();
    });
    this.componentDialogRef.componentInstance.save.subscribe((result: PoleState) => {
      this.componentDialogRef.close(result);
      this.addChild(result.component);
      this.ecFacadeService.traversal.recursive();
      this.save.emit();
    });

    return this.componentDialogRef.afterClosed();
  }

  private addChild(component: any) {
    if (!(this.equipment instanceof ElectricalContainer)) {
      throw new Error('Should be a ElectricalContainer');
    }
    component = this.ecFacadeService.director.getElectricEquipment(component, this.equipment);
    this.equipment.add(component);
  }

  removeRow(pole: { label: string | number; value: string }) {
    if (this.equipment instanceof ElectricalContainer) {
      const child = this.equipment.getChildren().find((c) => c.id === pole.value);
      this.equipment.remove(child);
      this.ecFacadeService.traversal.recursive();
      this.save.emit();
    }
  }

  onControlChange(key: string, $event: unknown) {
    console.log(`onControlChange `, key, $event);
  }
}
