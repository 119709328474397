import { ElectricalEquipment } from './electrical-equipment';
import { Ampere, Watt } from '../core/unit.model';
import { AvailableConfigType } from '../entities/base-component';
import { LineNeutral } from '../wires/line-neutral';

export class LightingChild extends ElectricalEquipment {
  constructor(protected _name?: string, protected _power = 0, protected _amount = 0) {
    super(_name, _power);
  }

  get current(): Ampere {
    return this.power / this.voltage;
  }

  get power(): Watt {
    return this._power * this._amount;
  }

  set power(value: number) {
    this._power = value;
  }

  set amount(value: number) {
    this._amount = value;
  }

  get amount() {
    return this._amount;
  }

  getWireForParent() {
    return [new LineNeutral()];
  }

  getAvailableConfig(): AvailableConfigType {
    return [
      {
        key: 'amount',
        value: this.amount,
        label: `Amount`,
        controlType: 'number',
        suffix: `Piece`
      },
      {
        key: 'power',
        value: this.power,
        label: `Power`,
        controlType: 'number',
        suffix: `Watt`
      }
    ];
  }
}
