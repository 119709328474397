import { Injectable } from '@angular/core';
import { EcEquipmentConfigureResources, EcNewEquipmentConfigureResources } from 'ec-bb';
import {
  AddMcbInstall,
  AddMeterInstall,
  Authority,
  ElectricalBuilder,
  ElectricalContainer,
  ElectricalDirector,
  ElectricalTraversal,
  HouseConstructionDirector,
  IncreaseMcbInstall,
  MaintenanceDirector,
  MEA,
  PEA
} from '@ec-workspace/ec-library';
import { PfeBusinessService } from '@allianz/ngx-pfe';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EcFacadeService {
  director!: ElectricalDirector;
  builder!: ElectricalBuilder;
  traversal!: ElectricalTraversal;
  authority!: Authority;
  root?: ElectricalContainer;

  isCompletedState$ = new BehaviorSubject(false);

  isInitialInstall = false;
  isInitialNewEquipment = false;

  private _existingState?: ElectricalContainer;

  currentEcEquipmentConfigureResources!:
    | EcEquipmentConfigureResources
    | EcNewEquipmentConfigureResources;

  startOverProcess$ = new Subject<EcEquipmentConfigureResources>();
  startOverProcessNewDevice$ = new Subject<EcNewEquipmentConfigureResources>();

  constructor(protected pfeBusinessService: PfeBusinessService) {
    console.log('EcFacadeService');
  }

  initialInstallation(
    config: EcEquipmentConfigureResources = this.currentEcEquipmentConfigureResources
  ) {
    this.currentEcEquipmentConfigureResources = config;
    this.setUpDirector(config);
    this.isInitialInstall = true;
  }

  initialForConfigExistingDevice() {
    this.director.initialForConfigExistingDevice();
  }

  initialForConfigNewDevice() {
    this.director.initialForConfigNewDevice();
  }

  private getAuthority(authority: EcEquipmentConfigureResources['authority']): Authority {
    if (authority === 'METROPOLITAN') {
      return new MEA();
    } else if (authority === 'PROVINCIAL') {
      return new PEA();
    }
    throw new Error(`Can not find authority ${authority}`);
  }

  setUpDirector(config: EcEquipmentConfigureResources) {
    if (config.calculatorType === 'MAINTENANCE') {
      this.director = new MaintenanceDirector();
    } else if (config.calculatorType === 'HOUSE_CONSTRUCTION') {
      this.director = new HouseConstructionDirector();
    } else if (config.calculatorType === 'EV_CHARGER') {
      if (config.installType === 'INCREASE_MCB_SIZE') {
        this.director = new IncreaseMcbInstall();
      } else if (config.installType === 'NEW_MCB') {
        this.director = new AddMcbInstall();
      } else if (config.installType === 'NEW_METER') {
        this.director = new AddMeterInstall();
      }
    }

    this.director.doublePoleType = config.mainDoublePoleType ?? 'Mcb2Pole';

    this.root = this.getAuthority(config.authority);
    if (!this.root) {
      throw new Error(`Root not found:`);
    }
    this.traversal = new ElectricalTraversal(this.root);

    this.builder = new ElectricalBuilder(this.root, this.traversal);
    this.director.setBuilder(this.builder);
  }

  isCompleted() {
    return this.traversal.getAllComponentsArray().every((c) => c.isCompletedState);
  }

  getChartData() {
    return {
      components: this.traversal.components,
      boxContainers: this.traversal.boxContainers,
      wires: this.traversal.wires
    };
  }

  getChartDataJson() {
    return {
      components: this.traversal.components,
      boxContainers: this.traversal.boxContainers,
      wires: this.traversal.wires
    };
  }

  saveExistingState() {
    this._existingState = Object.assign(
      Object.create(Object.getPrototypeOf(this.builder.root)),
      this.builder.root
    );
  }

  restoreExistingState() {
    if (this.builder && this._existingState) {
      this.builder.root = this._existingState;
      this.traversal.root = this._existingState;
    }
  }
}
