import { AfterViewInit, Component, Inject, OnInit, Optional } from '@angular/core';
import { envManager } from '../environment-manager';
import { AclService } from '@allianz/taly-acl/angular';
import { CHANNEL, CHANNEL_TOKEN } from '@allianz/taly-core';
import {
  NavigationConfig,
  TALY_FRAME_NAVIGATION_CONFIG,
  TALY_FRAME_FOOTER_CONFIG,
  TALY_FRAME_STAGE_CONFIG
} from '@allianz/taly-common/frame';
import { FooterConfiguration, StageConfiguration } from '@allianz/taly-core/schemas';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {
  title = $localize`:@@application.title: Electric Calculator`;

  



  constructor(
    private aclService: AclService,
    @Inject(CHANNEL_TOKEN) private _channel: CHANNEL,
    @Optional() @Inject(TALY_FRAME_NAVIGATION_CONFIG) public navigationConfig: NavigationConfig,
    @Optional() @Inject(TALY_FRAME_STAGE_CONFIG) public stageConfig: StageConfiguration,
    @Optional() @Inject(TALY_FRAME_FOOTER_CONFIG) public footerConfig: FooterConfiguration ) {}

  ngAfterViewInit(): void {
    console.log(`Generated with Schematics Version '${envManager.envData.schematicsVersionUsed}'`);
  }

  ngOnInit(): void {
    this.aclService.setEnvironmentValue('channel', this._channel);


  }

  


}
