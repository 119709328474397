import { EvChargerInstall } from './ev-charger-install.director';
import { Meter } from '../../entities/meter';
import { Watt } from '../../core/unit.model';
import { MiniatureCircuitBreaker } from '../../entities/miniature-circuit-breaker';
import { EvCharger } from '../../equipments/ev-charger';
import { BoxContainer } from '../../containers/box-container';
import { EcComponent } from '../../entities/ecComponent';
import { Rcbo2Pole } from '../../entities/rcbo-2-pole';
import { Mcb2Pole } from '../../entities/mcb-2-pole';

/**
 * Install Type 2
 */
export class AddMcbInstall extends EvChargerInstall {
  initialForConfigExistingDevice() {
    const doublePole = new Mcb2Pole();
    doublePole.lockConfigForNewDevice = true;
    doublePole.isExisting = true;
    doublePole.isEnabledForceSetCurrent = true;

    this.MCB1 = doublePole;
    const meter = new Meter();
    meter.isExisting = true;
    meter.maximumChildren = 2;
    this.targetMeter = meter;
    meter.add(doublePole);
    this.builder.addComponentToRoot(meter);
  }

  addEvChargerByName(name: string, power: Watt) {
    if (this.targetMeter) {
      // const length = ExistingMeter.getChildren().length;

      const newEvCharger = new EvCharger(name, power, false);
      const RCD = this.getRcd2Pole(newEvCharger);

      const MCB = new Mcb2Pole();
      MCB.lockConfigForNewDevice = true;
      MCB.description = `${newEvCharger.name}`;
      MCB.isExisting = false;
      MCB.add(RCD);

      this.targetMeter.add(MCB);

      const boxContainer = new BoxContainer();
      boxContainer.isExisting = false;
      boxContainer.name = `${MCB.name}`;
      boxContainer.add(MCB);
      boxContainer.mainInsider = MCB;
    }
  }

  addEvCharger(newEvCharger: EvCharger) {
    if (this.targetMeter) {
      const RCD = this.getRcd2Pole(newEvCharger);
      const MCB = new Mcb2Pole();
      MCB.lockConfigForNewDevice = true;
      MCB.description = `${newEvCharger.name}`;
      MCB.isExisting = false;
      MCB.add(RCD);
      MCB.mainDevice = newEvCharger;

      this.targetMeter.add(MCB);

      const boxContainer = new BoxContainer();
      boxContainer.isExisting = false;
      boxContainer.name = `${MCB.name}`;
      boxContainer.add(MCB);
      boxContainer.mainInsider = MCB;
    }
  }

  removeEvCharger(name: string) {
    const evCharger = this.traversal.findByName(name);
    this.traversal.findUp(evCharger, (component: EcComponent) => {
      if (component instanceof MiniatureCircuitBreaker) {
        component.getParent()?.remove(component);
      }
    });
  }

  initialForConfigNewDeviceStep1(): void {
    this.targetMeter.canAdd = true;
  }

  testingCompleteAllExising() {
    this.traversal.findChildren(this.traversal.root, (c) => {
      console.log(c.className);
      if (c.className === 'Meter') {
        c.currentSize = 40;
      }
      if (c.className === 'Rcbo2Pole') {
        c.currentSize = 50;
      }
    });
  }
}
