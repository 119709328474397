import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TalyCoreModule } from '@allianz/taly-core';
import { CcValidationErrorsModule } from '@allianz/common-building-block-components';
import { NxHeadlineModule } from '@aposin/ng-aquila/headline';
import { NxGridModule } from '@aposin/ng-aquila/grid';
import { NxFormfieldModule } from '@aposin/ng-aquila/formfield';
import { NxInputModule } from '@aposin/ng-aquila/input';
import { EcCalculatePriceComponent } from './ec-calculate-price.component';
import { NxIconModule } from '@aposin/ng-aquila/icon';
import { NxTableModule } from '@aposin/ng-aquila/table';
import { NxButtonModule } from '@aposin/ng-aquila/button';
import { NxDataDisplayModule } from '@aposin/ng-aquila/data-display';
import { NxNumberStepperModule } from '@aposin/ng-aquila/number-stepper';

@NgModule({
  declarations: [EcCalculatePriceComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TalyCoreModule,
    CcValidationErrorsModule,
    NxHeadlineModule,
    NxGridModule,
    NxFormfieldModule,
    NxInputModule,
    NxIconModule,
    NxTableModule,
    NxButtonModule,
    NxDataDisplayModule,
    NxNumberStepperModule
  ],
  exports: [EcCalculatePriceComponent]
})
export class EcCalculatePriceModule {}
