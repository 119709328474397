import { Pole } from '../entities/pole';
import { BoxContainer } from './box-container';
import { EcComponent, FlatJson } from '../entities/ecComponent';
import { DoublePole } from '../entities/double-pole';
import { Ground } from '../entities/ground';
import { AvailableConfigType } from '../entities/base-component';
import { GroundBar } from './ground-bar';

export class ConsumerBox extends BoxContainer {
  groundBar!: GroundBar;

  constructor(
    protected _name?: string,
    protected _socketNumber?: number,
    protected _initialPoleNumber?: number,
    public _isExisting: boolean = true,
    _isAddDefaultChild = true
  ) {
    super(_name);
    this.isExisting = _isExisting;
    this.socketNumber = _socketNumber;
    if (_isAddDefaultChild) {
      this.addDefaultChild(_isExisting);
    }
  }

  public addGroundBar() {
    const groundBar = new GroundBar(`Ground Bar for ${this.name}`);
    this.groundBar = groundBar;
    groundBar.isExisting = this.isExisting;
    groundBar.add(new Ground());
    this.add(groundBar);
    if (this._mainInsider) {
      this.groundBar.getChildren().forEach((c) => {
        if (!(c instanceof Ground)) {
          this.remove(c);
        }
      });
      this.groundBar.add(this.mainInsider);
    }
  }

  get socketNumber(): number | undefined {
    return this._socketNumber;
  }

  set socketNumber(socketNumber: number | undefined) {
    // const initialPoleNumber = 0
    if (socketNumber === undefined) {
      return;
    }
    this._socketNumber = socketNumber;
    this.clearPole();

    for (let i = 0; i < socketNumber; i++) {
      const pole = new Pole();
      pole.name = `${pole.className} ${i + 1}`;
      pole.isExisting = this._isExisting;
      this.add(pole);
      pole.currentSize = 0;
      pole.isEnabledForceSetCurrent = this.isEnabledForceSetCurrent;
    }
    if (this._mainInsider) {
      this.addPoleToMainInsider();
    }
  }

  private clearPole() {
    // this.poles = [];
    this.poles.forEach((p) => {
      this.remove(p);
    });
  }

  get mainInsider(): EcComponent {
    return this._mainInsider;
  }

  set mainInsider(component: EcComponent) {
    if (!(component instanceof DoublePole)) {
      throw Error(`Set wrong type component ${component.type}`);
    }
    this._mainInsider = component;
    this._mainInsider.isMainBreaker = true;
    this.addPoleToMainInsider();
    this.connectGroundBarToMainInsider();
  }

  private addPoleToMainInsider() {
    this._mainInsider.setChildren([]);
    this.poles.forEach((p) => this._mainInsider.add(p));
  }

  addDefaultChild(isExisting: boolean) {
    // const ground = new Ground();
    // ground.isExisting = isExisting;
    // this.addDefaultChildHook(ground);
    // this._mainInsider.add(ground);
    this.addGroundBar();
  }

  addDefaultChildHook(ground: Ground) {}

  get poles() {
    return this.children.filter((c) => c instanceof Pole);
  }

  connectPole(equipment: EcComponent, _pole?: Pole) {
    if (_pole) {
      _pole.add(equipment);
      return;
    }
    for (const pole of this.poles) {
      if (pole.getChildren().length <= 0) {
        if (pole instanceof Pole) {
          pole.add(equipment);
          return;
        }
      }
    }
    console.warn(`Can not connect to any pole`);
  }

  add(componentChild: EcComponent) {
    super.add(componentChild);
    if (componentChild instanceof Pole) {
      this.poles.push(componentChild);
    }
    this.afterAdd(componentChild);
  }

  afterAdd(componentChild: EcComponent) {}

  remove(componentChild: EcComponent) {
    if (componentChild instanceof Pole) {
      const poleIndex = this.children.indexOf(componentChild);
      this.children.splice(poleIndex, 1);
    } else {
      super.remove(componentChild);
    }
    this.mainInsider.remove(componentChild);
  }

  get isCompletedState() {
    return !(this.isExisting && this.socketNumber === undefined);
  }

  getAvailableConfig(): AvailableConfigType {
    return [
      {
        key: `socketNumber`,
        value: this.socketNumber,
        controlType: 'radio',
        label: $localize`Amount of Socket (Equipment which is connected to the pole will be reset.)`,
        options: [
          { label: '4', value: 4 },
          { label: '6', value: 6 },
          { label: '10', value: 10 },
          { label: '14', value: 14 },
          { label: '18', value: 18 }
        ]
      }
    ];
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  getJsonData(): any {
    return {
      ...super.getJsonData()
    };
  }

  getFlatJson(): FlatJson {
    return {
      ...super.getFlatJson(),
      poles: this.poles.map((p) => p.id),
      children: this.children?.map((c) => c.id),
      socketNumber: this.socketNumber,
      texts: [`socket: ${this.socketNumber} `]
    };
  }

  get canConfig() {
    return !this._isLock;
  }

  private connectGroundBarToMainInsider() {
    if (this.groundBar) {
      this.groundBar.add(this.mainInsider);
      // this.mainInsider.add(this.groundBar);
    }
  }

  get size() {
    return this.socketNumber;
  }
}
