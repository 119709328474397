import { Injectable } from '@angular/core';
import { ClusterNode, Edge, Graph } from '@swimlane/ngx-graph';
import { ReplaySubject } from 'rxjs';
import { ComponentsState, FlatJson } from '@ec-workspace/ec-library';
import { EquipmentFormComponent } from '../shared/components/equipment-form/equipment-form.component';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { ChangeEquipmentFormComponent } from '../shared/components/change-equipment-form/change-equipment-form.component';

@Injectable({
  providedIn: 'root'
})
export class EcNgxGraphService {
  public graphModel$ = new ReplaySubject<Graph>(1);

  componentDialogRef!: NxModalRef<EquipmentFormComponent>;
  componentDialogRef2!: NxModalRef<ChangeEquipmentFormComponent>;

  constructor(public dialogService: NxDialogService) {}

  public loadComponentsConfig(config: ComponentsState) {
    this.generateGraphModel(config);
  }

  /**
   * Generates the graph data model out of the navigation config.
   *  Needs to run every time the config changes.
   */
  private generateGraphModel(state: ComponentsState) {
    console.log(`state in graph`);
    console.log(state);
    let nodes: any[] = [];
    let edges: Edge[] = [];
    let clusters: ClusterNode[] = [];

    function getNodeClass(c: FlatJson) {
      return {
        isExisting: c.isExisting,
        isNew: !c.isExisting,
        isOverCurrent: c.isOverCurrent,
        isCompletedState: c.isCompletedState,
        isNotCompletedState: !c.isCompletedState,
        isError: c.isError,
        isReadOnly: c.canConfig
      };
    }

    function getEmoji(data: FlatJson): string {
      let emoji = '';

      if (data.isError) {
        emoji += '🔥';
      }
      return emoji;
    }

    const getActions = (data: FlatJson) => {
      const actions = [];
      if (data.canConfig) {
        actions.push({
          label: 'config',
          icon: '✏️',
          onClick: (node) => {
            this.openEquipmentForm(node.id);
          }
        });
      }
      if (data.canAdd) {
        actions.push({
          label: 'add',
          icon: '➕',
          onClick: (node) => {
            this.openEquipmentForm(node.id);
          }
        });
      }
      if (data.canChangeEquipment) {
        actions.push({
          label: 'change',
          icon: '🔄',
          onClick: (node) => {
            this.openChnageEquipmentForm(node.id);
          }
        });
      }
      return actions;
    };

    function getGraphData(c: FlatJson) {
      return {
        id: c.id,
        label: `${c.shortName ?? c.name} ${getEmoji(c)}`,
        data: {
          ...c,
          actions: getActions(c),
          class: getNodeClass(c)
        }
      };
    }

    nodes = Object.values(state.components).map((c: FlatJson) => {
      return getGraphData(c);
    });

    const wiresArray = Object.values(state.wires);

    edges = wiresArray.map((c: FlatJson): Edge => {
      const json = getGraphData(c);
      // @ts-ignore
      json.data.class[c.type] = true;

      json.data.tooltip = json.data.name;
      if (json.data.canConfig) {
        const length = json.data.length === undefined ? '?' : json.data.length;
        json.label += ` = ${length} m`;
        json.data.tooltip += ` = ${length} m `;
      }

      json.data.tooltip += `, ${json.data?.current?.toFixed(2) ?? 0} A`;

      // add size wire
      json.data.tooltip += `, size: ${json.data?.size ?? '?'} `;

      console.log(json.data);

      return {
        ...json,
        target: c.input,
        source: c.output
      };
    });

    clusters = Object.values(state.boxContainers).map((c: FlatJson): ClusterNode => {
      return {
        ...getGraphData(c),
        childNodeIds: c.children
      };
    });

    const graphModel: Graph = {
      nodes,
      edges,
      clusters
    };

    this.graphModel$.next(graphModel);
  }

  openEquipmentForm(id: string) {
    this.componentDialogRef = this.dialogService.open(EquipmentFormComponent, {
      showCloseIcon: true,
      data: {
        id: id
      }
    });
    this.componentDialogRef.componentInstance.cancel.subscribe(() => {
      this.componentDialogRef.close();
    });
    this.componentDialogRef.componentInstance.save.subscribe((result) => {
      this.componentDialogRef.close(result);
    });

    return this.componentDialogRef.afterClosed();
  }

  openChnageEquipmentForm(id: string) {
    this.componentDialogRef2 = this.dialogService.open(ChangeEquipmentFormComponent, {
      showCloseIcon: true,
      data: {
        id: id
      }
    });
    this.componentDialogRef2.componentInstance.cancel.subscribe(() => {
      this.componentDialogRef2.close();
    });
    this.componentDialogRef2.componentInstance.save.subscribe((result) => {
      this.componentDialogRef2.close(result);
    });

    return this.componentDialogRef2.afterClosed();
  }
}
