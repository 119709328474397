import { Authority } from './authority';
import { Wire } from '../wires/wire';
import { Voltage } from '../core/unit.model';
import { AVAILABLE_MCB_SIZE, AVAILABLE_METER_SIZE } from '../core/constant';

/**
 * ส่วนภูมิภาค
 */
export class PEA extends Authority {
  get voltage(): Voltage {
    return 220;
  }

  get availableMeterSize(): AVAILABLE_METER_SIZE[] {
    return [15, 45, 100];
  }

  get availableMCBSize(): AVAILABLE_MCB_SIZE[] {
    return [16, 50, 100];
  }

  get minimumWireSizeMappers() {
    return {
      15: 4,
      45: 10,
      100: 35
    };
  }

  protected getWireForParent(): Wire[] {
    return [];
  }
}
