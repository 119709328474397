import { NavigationConfig } from '@allianz/taly-common/frame';
import { FooterConfiguration, SectionConfiguration, StageConfiguration } from '@allianz/taly-core/schemas';

declare var $localize: any;

const SECTIONS: SectionConfiguration[] = [
    {
        id: "1",
        label: $localize `:@@frame.navigation.sections.1:Set up`,
        pages: ["calculator-type", "install-type", "electricity-authority"]
    },
    {
        id: "2",
        label: $localize `:@@frame.navigation.sections.2:Config Device`,
        pages: ["config-all-existing-device", "config-new-device"]
    },
    {
        id: "3",
        label: $localize `:@@frame.navigation.sections.3:Price Summary`,
        pages: ["price-summary"]
    }
];

const FOOTER: FooterConfiguration | undefined = {
    copyright: $localize `:@@frame.footer.copyright:© Electric Calculator Software 2022`,
    links: []
};

const STAGE = undefined;

export function getNavigationConfig(): NavigationConfig {
  return {
    sections: new Map(SECTIONS.map((section) => [section.id, section]))
  }
}

export function getFooterConfig(): FooterConfiguration | undefined {
  return FOOTER;
}

export function getStageConfig(): StageConfiguration | undefined {
  return STAGE;
}
