import { ElectricalBase } from '../core/common.interface';
import { ICurrentContainer, JsonDataType } from '../core/current-container.interface';
import {
  Ampere,
  BoxContainer,
  EcComponent,
  STORE_CLASS,
  Voltage,
  Watt
} from '@ec-workspace/ec-library';
import {
  PriceSummary,
  Product
} from '../../../../ec-bb/src/lib/ec-price-summary/ec-price-summary.model';
import {
  CalculatorType,
  InstallType
} from '../../../../ec-bb/src/lib/ec-mdb-manager/ec-mdb-manager.model';

export type ControlType = 'number' | 'radio' | 'text' | 'crud';

export interface InputFieldConfig {
  key: string;
  value: unknown;
  controlType: ControlType;
  label?: string;
  suffix?: string;
  options?: { label: string | number; value: unknown }[];
  buttons?: { label: string | number; value: unknown }[];
  onChange?: Function;
  min?: number;
  max?: number;
}

export type AvailableConfigType = null | InputFieldConfig[];

export abstract class BaseComponent implements ElectricalBase, ICurrentContainer, JsonDataType {
  id: string;

  protected _currentSize?: Ampere;

  protected _isExisting?: boolean = true;

  protected _current: Ampere = 0;
  protected _power: Watt = 0;
  protected _voltage: Voltage = 0;

  _isLock = false;

  protected _canAdd = false;

  private _description!: string;

  protected toleranceFactor = 1;

  toleranceFactorSize = 1;

  public className: string = '';

  protected _container!: BoxContainer | null;

  protected _shortName? = '';

  private _shouldBuy?: boolean;

  private _canChangeEquipment?: boolean = true;

  protected _type?: string;

  mainDevice?: EcComponent;

  protected _isConfigNewMode = false;

  protected _newCurrentSize?: number;

  get newCurrentSize(): number | undefined {
    return this._newCurrentSize;
  }

  set newCurrentSize(value: number) {
    this._newCurrentSize = value;
  }

  get isConfigNewMode(): boolean {
    return this._isConfigNewMode;
  }

  set isConfigNewMode(value: boolean) {
    this._isConfigNewMode = value;
  }

  constructor(protected _name?: string) {
    this.id = BaseComponent.uniqueID();
    this.className = this.constructor.name;
    this._shortName = _name;
  }

  get shouldBuy(): boolean {
    if (this._shouldBuy !== undefined) {
      return this._shouldBuy;
    }
    return this.isOverCurrent || !this.isExisting;
  }

  set shouldBuy(value: boolean | undefined) {
    this._shouldBuy = value;
  }

  get isExisting(): boolean {
    return this._isExisting || false;
  }

  set isExisting(val: boolean) {
    this._isExisting = val;
  }

  get isNew(): boolean {
    return !this.isExisting;
  }

  get name() {
    return this._name ?? this.constructor.name;
  }

  set name(value: string) {
    this._name = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get shortName(): string {
    return this._shortName ?? this.name;
  }

  set shortName(value: string) {
    this._shortName = value;
  }

  set type(value) {
    this._type = value;
  }

  get type() {
    return this._type ?? this.constructor.name;
  }

  get current(): Ampere {
    return this._current;
  }

  set current(value: Ampere) {
    this._current = value;
  }

  get power(): Watt {
    return this._power;
  }

  set voltage(v: Voltage) {
    this._voltage = v;
  }

  get voltage(): Voltage {
    return this._voltage;
  }

  get currentSize(): Ampere | undefined {
    return this._currentSize;
  }

  set currentSize(value: Ampere | undefined) {
    this._currentSize = value;
  }

  get isOverCurrent(): boolean {
    if (!this.isExisting) {
      return false;
    }
    if (this.currentSize === undefined) {
      return false;
    }
    const isOverCurrent = this.current > this.currentSize * this.toleranceFactor;
    // if (this.isConfigNewMode) {
    //   this._isLock = !isOverCurrent;
    // }
    return isOverCurrent;
  }

  get isError(): boolean {
    return false;
  }

  get isCompletedState() {
    return true;
  }

  static uniqueID() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }

  abstract getAvailableConfig(): AvailableConfigType | null;

  abstract getAvailableTypeOptions(): AvailableConfigType | null;

  get canConfig(): boolean {
    if (this._isLock) {
      return false;
    }
    return !this.isExisting && this.getAvailableConfig() !== null;
  }

  get canChangeEquipment(): boolean {
    if (this._isLock) {
      return false;
    }
    if (!this._canChangeEquipment) {
      return false;
    }
    return this.getAvailableTypeOptions() !== null;
  }

  set canChangeEquipment(value) {
    this._canChangeEquipment = value;
  }

  lock() {
    this._isLock = true;
  }

  unlock() {
    this._isLock = false;
  }

  get canAdd(): boolean {
    return this._canAdd;
  }

  set canAdd(value: boolean) {
    this._canAdd = value;
  }

  getFlatJson(): any {
    return {
      id: this.id,
      name: this.name,
      shortName: this.shortName,
      type: this.type,
      className: this.className,
      description: this.description,
      current: this.current,
      power: this.power,
      voltage: this.voltage,
      currentSize: this.currentSize,
      exisingCurrentSize: this.newCurrentSize,
      isExisting: this.isExisting,
      isError: this.isError,
      isCompletedState: this.isCompletedState,
      isOverCurrent: this.isOverCurrent,
      canConfig: this.canConfig,
      canAdd: this.canAdd
    };
  }

  getJsonData() {
    return this.getFlatJson();
  }

  get size(): number | undefined {
    return this.current;
  }

  get quantity(): number {
    return 1;
  }

  findProduct(products: Product[]) {
    for (const product of products) {
      // @ts-ignore
      if (!STORE_CLASS[product.type]) {
        console.warn(`${product.type} not found`);
        continue;
      }

      if (this.type === product.type) {
        if (!product.size) {
          return product;
        }
        if (this.isOverCurrent) {
          if (this.size) {
            if (product.size >= (this.size ?? 0)) {
              return product;
            }
          }
        } else {
          if (this.currentSize) {
            if (product.size === this.currentSize) {
              return product;
            }
          } else if (product.size >= (this.size ?? 0)) {
            return product;
          }
        }
      }
    }
    console.warn(`Can not find name: ${this.name} type: ${this.type} size: ${this.size}`);
    return undefined;
  }

  getPriceSummary(products: Product[]): PriceSummary[] {
    const priceSummary: PriceSummary[] = [];
    const product = this.findProduct(products);
    let description = `${this.name}`;
    if (this.description) {
      description += ` for ${this.description}`;
    }
    if (!product) {
      return [];
    }
    priceSummary.push({
      name: this.name,
      type: product.type,
      description,
      productDescription: product.description,
      quantity: this.quantity.toString(),
      unitPrice: product.price,
      amount: this.quantity * product.price
    });
    return priceSummary;
  }

  afterSubmit(): void {
    console.log('no action after submit');
  }
}
