// Please remove unused imports according to the Plugin's requirement
import { Injectable } from '@angular/core';
import {
  PfeBusinessService,
  PfeConfigurationService,
  PfeNavigationService,
  PfeStateService,
  PfeUtilService
} from '@allianz/ngx-pfe';
import { connectViaExtension } from 'remotedev';
import { debounceTime } from 'rxjs/operators';
import { initialStateEvMode1, initialStateMA, initialStateNewHome } from './init-state';

const DEVTOOLSURLPARAM = 'devtools';

@Injectable()
export class ReduxDevtool {
  remotedev: any;
  // class needs to implement HTTP Interceptor Plugin if it is an Interceptor Plugin
  // or PluginValidator/AsyncPluginValidator, if it is a sync or async Validator Plugin
  // For more information on the implementation, please check https://gdf-taly-workspace.azureedge.net/additional-documentation/plugins.html
  constructor(
    private pfeBusinessService: PfeBusinessService,
    private pfeStateService: PfeStateService,
    private pfeConfigurationService: PfeConfigurationService,
    private pfeNavigationService: PfeNavigationService,
    private pfeUtilService: PfeUtilService
  ) {}

  init(): void {
    console.log('ReduxDevtool init');
    this.remotedev = connectViaExtension();
    const urlParam = this.pfeUtilService.getURLParam(DEVTOOLSURLPARAM);

    this.pfeStateService.pfeUserInputState$.pipe(debounceTime(500)).subscribe((value) => {
      this.remotedev.send('debug', value);
    });

    // this.pfeStateService.setFullState(initialStateEvMode1);
    // this.pfeStateService.setFullState(initialStateEvMode2);
    // this.pfeStateService.setFullState(initialStateEvMode3);

    // this.pfeStateService.setFullState(initialStateNewHome);
    // this.pfeStateService.setFullState(initialStateMA);
  }
}
