const GROUND_SIZE_MAPPER = [
  { in: 20, cable: 2.5 },
  { in: 40, cable: 4 },
  { in: 70, cable: 6 },
  { in: 100, cable: 10 },
  { in: 200, cable: 16 }
];

const WIRE_SIZE_MAPPER = [
  { in: 21, cable: 2.5 },
  { in: 28, cable: 4 },
  { in: 36, cable: 6 },
  { in: 50, cable: 10 },
  { in: 66, cable: 16 },
  { in: 88, cable: 25 },
  { in: 109, cable: 35 },
  { in: 131, cable: 50 },
  { in: 167, cable: 70 },
  { in: 202, cable: 95 },
  { in: 234, cable: 120 },
  { in: 261, cable: 150 },
  { in: 297, cable: 185 },
  { in: 348, cable: 240 }
];

const MAIN_GROUND_WIRE_MAPPER = [
  { main: 2.5, ground: 2.5 },
  { main: 4.0, ground: 4.0 },
  { main: 6.0, ground: 6.0 },
  { main: 10.0, ground: 10.0 },
  { main: 16.0, ground: 16.0 },
  { main: 25.0, ground: 16.0 },
  { main: 35.0, ground: 16.0 },
  { main: 50.0, ground: 25.0 },
  { main: 70.0, ground: 35.0 },
  { main: 95.0, ground: 50.0 },
  { main: 120.0, ground: 70.0 },
  { main: 150.0, ground: 95.0 },
  { main: 185.0, ground: 95.0 },
  { main: 240.0, ground: 120.0 },
  { main: 300.0, ground: 150.0 }
];

const POLE_SIZE = [16, 20, 25, 32, 40, 50, 63, 80, 100, 125];

export { GROUND_SIZE_MAPPER, WIRE_SIZE_MAPPER, POLE_SIZE, MAIN_GROUND_WIRE_MAPPER };
