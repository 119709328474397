import { Wire } from './wire';
import {
  PriceSummary,
  Product
} from '../../../../ec-bb/src/lib/ec-price-summary/ec-price-summary.model';
import { Line } from './line';
import { Neutral } from './neutral';

export class LineNeutral extends Wire {
  get shortName(): string {
    return `L & N`;
  }

  get name(): string {
    return `Line & Neutral`;
  }

  getPriceSummary(products: Product[]): PriceSummary[] {
    const childWires = [new Line(), new Neutral()];
    childWires.forEach((w) => {
      w.setComponents(this.input, this.output);
      w.description = this.description;
      w.length = this.length;
      w.size = this.size;
    });
    return childWires.flatMap((w) => w.getPriceSummary(products));
  }

  get type() {
    return 'Wire';
  }
}
