import { PfePageMappingService, PageDefinition, PageConfig } from '@allianz/ngx-pfe';

import { calculatorTypeRoute } from './pages/calculator-type/calculator-type.route';
import { electricityAuthorityRoute } from './pages/electricity-authority/electricity-authority.route';
import { installTypeRoute } from './pages/install-type/install-type.route';
import { configAllExistingDeviceRoute } from './pages/config-all-existing-device/config-all-existing-device.route';
import { configNewDeviceRoute } from './pages/config-new-device/config-new-device.route';
import { priceSummaryRoute } from './pages/price-summary/price-summary.route';


const pageConfigModel = <TObj>() => (name: keyof TObj) => name;
const getConfigPropertyName = pageConfigModel<ItmpPageConfig>();

export const pages = new Map<string, PageDefinition>();

// Page Config List
export interface ItmpPageConfig extends PageConfig {
  calculatorTypePageConfig?: any;
  electricityAuthorityPageConfig?: any;
  installTypePageConfig?: any;
  configAllExistingDevicePageConfig?: any;
  configNewDevicePageConfig?: any;
  priceSummaryPageConfig?: any;
}

// Page Mappings

PfePageMappingService.addLazyLoadedPageToMap(
  pages,
  getConfigPropertyName('calculatorTypePageConfig'),
  calculatorTypeRoute
);

PfePageMappingService.addLazyLoadedPageToMap(
  pages,
  getConfigPropertyName('electricityAuthorityPageConfig'),
  electricityAuthorityRoute
);

PfePageMappingService.addLazyLoadedPageToMap(
  pages,
  getConfigPropertyName('installTypePageConfig'),
  installTypeRoute
);

PfePageMappingService.addLazyLoadedPageToMap(
  pages,
  getConfigPropertyName('configAllExistingDevicePageConfig'),
  configAllExistingDeviceRoute
);

PfePageMappingService.addLazyLoadedPageToMap(
  pages,
  getConfigPropertyName('configNewDevicePageConfig'),
  configNewDeviceRoute
);

PfePageMappingService.addLazyLoadedPageToMap(
  pages,
  getConfigPropertyName('priceSummaryPageConfig'),
  priceSummaryRoute
);

