import { NgxPfeConfig } from '@allianz/ngx-pfe';

declare var $localize: any;

export const PFE_CONFIG: NgxPfeConfig = {
    pagesConfiguration: [
        {
            pageId: "calculator-type",
            hideBackButton: true,
            calculatorTypePageConfig: {}
        },
        {
            pageId: "install-type",
            installTypePageConfig: {}
        },
        {
            pageId: "electricity-authority",
            hideNextButton: false,
            electricityAuthorityPageConfig: {}
        },
        {
            pageId: "config-all-existing-device",
            configAllExistingDevicePageConfig: {}
        },
        {
            pageId: "config-new-device",
            configNewDevicePageConfig: {}
        },
        {
            pageId: "price-summary",
            hideNextButton: true,
            priceSummaryPageConfig: {}
        }
    ],
    navConfiguration: {
        firstPage: {
            nextOptionList: [
                {
                    nextPageId: "calculator-type"
                }
            ]
        },
        pages: [
            {
                pageId: "calculator-type",
                nextOptionList: [
                    {
                        nextPageId: "electricity-authority"
                    }
                ]
            },
            {
                pageId: "electricity-authority",
                backOptionList: [
                    {
                        nextPageId: "calculator-type"
                    }
                ],
                nextOptionList: [
                    {
                        nextPageId: "install-type",
                        conditions: [
                            {
                                expression: "{$['calculator-type'].data} === 'EV_CHARGER'"
                            }
                        ]
                    },
                    {
                        nextPageId: "config-all-existing-device"
                    }
                ],
                onPageLeaveActions: [
                    {
                        type: "UPDATE_STATE_VALUES",
                        stateUpdates: [
                            {
                                key: "$.availableInstallOption",
                                value: [
                                    "INCREASE_MCB_SIZE",
                                    "NEW_MCB"
                                ],
                                conditions: [
                                    {
                                        expression: "{$['electricity-authority'].data} === 'METROPOLITAN'"
                                    }
                                ]
                            },
                            {
                                key: "$.availableInstallOption",
                                value: [
                                    "INCREASE_MCB_SIZE",
                                    "NEW_METER"
                                ],
                                conditions: [
                                    {
                                        expression: "{$['electricity-authority'].data} === 'PROVINCIAL'"
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                pageId: "install-type",
                backOptionList: [
                    {
                        nextPageId: "electricity-authority"
                    }
                ],
                nextOptionList: [
                    {
                        nextPageId: "config-all-existing-device"
                    }
                ]
            },
            {
                pageId: "config-all-existing-device",
                nextOptionList: [
                    {
                        nextPageId: "config-new-device"
                    }
                ]
            },
            {
                pageId: "config-new-device",
                nextOptionList: [
                    {
                        nextPageId: "price-summary"
                    }
                ]
            },
            {
                pageId: "price-summary"
            }
        ]
    },
    appConfiguration: {
        pfeConfig: {
            validationErrorStateKey: "validationErrorData",
            backButtonLabel: "Back",
            nextButtonLabel: "Next"
        }
    }
} as unknown as NgxPfeConfig;
