import { Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { createBuildingBlockProvider } from '@allianz/taly-core';
import { LocalizeFn } from '@angular/localize/init';

declare let $localize: LocalizeFn;

@UntilDestroy()
@Component({
  selector: 'bb-ec-mdb-manager',
  templateUrl: 'ec-mdb-manager.component.html',
  styleUrls: ['ec-mdb-manager.component.scss'],
  providers: [createBuildingBlockProvider(EcMdbManagerComponent)]
})
export class EcMdbManagerComponent {}
