import { ElectricalContainer } from './electrical-container';
import { Wire } from '../wires/wire';
import { Line } from '../wires/line';
import { Neutral } from '../wires/neutral';
import { BoxContainer } from '../containers/box-container';
import { EcComponent } from './ecComponent';
import { ElectricalEquipment } from '../equipments/electrical-equipment';
import { Ampere } from '../core/unit.model';

export class MiniatureCircuitBreaker extends ElectricalContainer {
  constructor(protected _name: string = 'MCB', _maximumCurrent?: Ampere, _isExisting?: boolean) {
    super(_name, _maximumCurrent, _isExisting);
  }

  setBoxContainer(boxContainer: BoxContainer) {
    if (boxContainer) {
      boxContainer.name += ` ${this.name}`;
      boxContainer.mainInsider = this;
      this.container = boxContainer;
    } else {
      this.container = new BoxContainer(`BoxContainer ${this.name}`);
    }
    if (this._container) {
      this._container.add(this);
    }
  }

  add(component: EcComponent) {
    super.add(component);
    if (component instanceof ElectricalEquipment) {
      return;
    }
    component.container = this._container;
    this._container?.add(component);
  }

  protected getWireForParent(): Wire[] {
    return [new Line(), new Neutral()];
  }
}
