
import { EnvObj } from '@allianz/taly-core';
import { environment } from './environments/environment';

let _envData: EnvObj = environment;

export const envManager = {
  get envData(): EnvObj {
    return _envData;
  }
}
