import { NgModule } from '@angular/core';
import {
  TALY_FRAME_NAVIGATION_CONFIG,
  TALY_FRAME_FOOTER_CONFIG,
  TALY_FRAME_STAGE_CONFIG
} from '@allianz/taly-common/frame';
import { getNavigationConfig, getFooterConfig, getStageConfig } from './frame-data';

@NgModule({
  providers: [
    {
      provide: TALY_FRAME_NAVIGATION_CONFIG,
      useFactory: getNavigationConfig
    },
    {
      provide: TALY_FRAME_FOOTER_CONFIG,
      useFactory: getFooterConfig
    },
    {
      provide: TALY_FRAME_STAGE_CONFIG,
      useFactory: getStageConfig
    }
  ]
})
export class FrameDataModule {}
