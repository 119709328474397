import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { NX_MODAL_DATA, NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { PoleFormComponent, PoleState } from '../pole-form/pole-form.component';
import { EcFacadeService } from '../../services/ec-facade.service';
import {
  AddMcbInstall,
  AddMeterInstall,
  AllElectricalEquipmentType,
  AvailableConfigType,
  ConsumerBox,
  DoublePole,
  ElectricalContainer,
  HouseConstructionDirector,
  Mcb2Pole,
  Meter,
  Rcbo1Pole,
  Wire
} from '@ec-workspace/ec-library';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { NxMessageToastService } from '@aposin/ng-aquila/message';

@Component({
  selector: 'itmp-equipment-form',
  templateUrl: './equipment-form.component.html',
  styleUrls: ['./equipment-form.component.scss']
})
export class EquipmentFormComponent implements OnInit {
  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter();

  equipment!: AllElectricalEquipmentType;

  formGroup!: FormGroup | null;

  getAvailableConfig!: AvailableConfigType;

  componentDialogRef!: NxModalRef<PoleFormComponent>;
  canAddChild = true;
  deleteButtons: { label: string; value: string }[] = [];

  constructor(
    @Inject(NX_MODAL_DATA) public data: { id: string },
    protected ecFacadeService: EcFacadeService,
    public dialogService: NxDialogService,
    private readonly messageToastService: NxMessageToastService
  ) {
    this.equipment = this.ecFacadeService.traversal.allComponentsObject[this.data.id];
    this.formGroup = this.getFormGroup();
  }

  private getFormGroup() {
    if ('getAvailableConfig' in this.equipment) {
      this.getAvailableConfig = this.equipment.getAvailableConfig() ?? [];
      const formConfig: {
        [key: string]: AbstractControl;
      } = {};

      for (const config of this.getAvailableConfig) {
        if (config.controlType === 'crud' && 'canAddChild' in this.equipment) {
          this.canAddChild = this.equipment.canAddChild;
          if (!(this.ecFacadeService.director instanceof HouseConstructionDirector)) {
            config.options = config.options?.filter((o) => o.value === 'EV_CHARGER');
          }
          if (
            this.ecFacadeService.director instanceof AddMeterInstall ||
            this.ecFacadeService.director instanceof AddMcbInstall
          ) {
            if (this.equipment instanceof Meter) {
              config.options?.push({
                label: `Consumer Box`,
                value: 'CONSUMER_BOX'
              });
            }
          }
        }

        if ('getChildren' in this.equipment) {
          this.deleteButtons = this.equipment
            .getChildren()
            .filter((c) => c.isNew)
            .map((c) => ({
              label: c.description ?? c.name,
              value: c.id
            }));
        }

        if (config.key === 'children') {
          continue;
        }
        formConfig[config.key] = new FormControl(
          (this.equipment as any)[config.key],
          Validators.required
        );
      }
      return new FormGroup(formConfig);
    }
    return null;
  }

  ngOnInit(): void {}

  onSubmit() {}

  isFormValid() {
    return this.formGroup?.valid;
  }

  submit() {
    if (this.isFormValid()) {
      for (const config of this.getAvailableConfig ?? []) {
        // if (config.key in this.equipment) {
        if (config.key === 'children') {
          continue;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.equipment[config.key] = this.formGroup?.controls[config.key].value;
        // }
      }
      this.equipment.afterSubmit();
      this.ecFacadeService.traversal.recursive();
      this.save.emit();
    }
  }

  addRow(param: { value: string; label: string }) {
    if (param.value === 'CONSUMER_BOX') {
      const mcb = new Mcb2Pole();
      const newConsumerBox = new ConsumerBox(`Consumer Box 1`);
      newConsumerBox.isExisting = false;
      newConsumerBox.socketNumber = 4;
      newConsumerBox.add(mcb);
      newConsumerBox.mainInsider = mcb;

      this.ecFacadeService.director.targetConsumerBox = newConsumerBox;

      this.addChild(mcb);
      this.ecFacadeService.traversal.recursive();
      this.save.emit();

      return;
    }
    this.openFromComponent({
      type: param.value,
      label: param.label,
      voltage: this.ecFacadeService.root?.voltage
    });
  }

  openFromComponent(data: object): Observable<PoleState> {
    this.componentDialogRef = this.dialogService.open(PoleFormComponent, {
      showCloseIcon: true,
      data: data
    });
    this.componentDialogRef.componentInstance.cancel.subscribe(() => {
      this.componentDialogRef.close();
    });
    this.componentDialogRef.componentInstance.save.subscribe((result: PoleState) => {
      console.warn('ssss', this.equipment, result);

      /**
       *   - ถ้าเมนเป็นกันดูด โหลดปลั๊กไฟไม่
       *      ต้องเป็นกันดูดแล้ว
       *   - ถ้าเมนไม่เป็นกันดูด ต้องบังคับให้ลูกย่อยที่
       *      เป็นโหลดปลั๊กไฟต้องเป็นกันดูด
       */
      if (result.type === 'POWER_SOCKET') {
        if (!(this.equipment instanceof Wire)) {
          const mainBreaker = this.equipment.getParent();

          if (mainBreaker instanceof DoublePole && !mainBreaker?.isOverCurrentProtection) {
            const mainInsiderChildren = mainBreaker?.getChildren();
            mainInsiderChildren?.find((c, index) => {
              if (c === this.equipment) {
                c.name = `Rcbo1Pole ${index + 1}`;
                c.type = 'Rcbo1Pole';
              }
            });
          }
        }
      }

      /**
       *   - ถ้าในเซอร์กิตย่อยมี ev charger
       *      เมนเบรคเกอร์ต้องห้ามเป็นกันดูด
       */
      if (result.type === 'EV_CHARGER') {
        if (!(this.equipment instanceof Wire)) {
          const mainBreaker = this.equipment.getParent();

          /**
           * ยกเว้น Rcd2PoleTypeB
           */
          if (
            mainBreaker instanceof DoublePole &&
            mainBreaker?.isOverCurrentProtection &&
            !(mainBreaker.type === 'Rcd2PoleTypeB')
          ) {
            this.messageToastService.open(
              $localize`Can not add Ev Charger because Main circuit breaker has over current protection. Please change type of circuit breaker.`,
              {
                context: 'error',
                duration: 5000
              } as any
            );

            this.componentDialogRef.close(result);
            this.save.emit();
            return;
          }
        }
      }

      this.componentDialogRef.close(result);
      this.addChild(result.component);
      this.ecFacadeService.traversal.recursive();
      this.save.emit();
    });

    return this.componentDialogRef.afterClosed();
  }

  private addChild(component: any) {
    if (!(this.equipment instanceof ElectricalContainer)) {
      throw new Error('Should be a ElectricalContainer');
    }
    component = this.ecFacadeService.director.getElectricEquipment(component, this.equipment);

    this.equipment.add(component);
  }

  removeRow(pole: { label: string | number; value: string }) {
    if (this.equipment instanceof ElectricalContainer) {
      let index;
      const child = this.equipment.getChildren().find((c, i) => {
        index = i;
        return c.id === pole.value;
      });
      this.equipment.remove(child);
      if (this.equipment.type === 'Rcbo1Pole') {
        this.equipment.type = 'Pole';
        this.equipment.name = `Pole ${index + 1}`;
      }
      this.ecFacadeService.traversal.recursive();
      this.save.emit();
    }
  }

  onControlChange(key: string, $event: unknown) {
    console.log(`onControlChange }`, key, $event);
  }
}
