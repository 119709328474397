import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  AbstractBuildingBlock,
  createBuildingBlockProvider,
  ValidationConfig,
  ValidationConfigItem
} from '@allianz/taly-core';
import { FormBindingReturnValue } from '@allianz/taly-acl/form-support';
import {
  EcEquipmentConfigureResources,
  EcEquipmentConfigureState
} from './ec-equipment-configure.model';
import { EcFacadeService } from '../shared/services/ec-facade.service';
import { PfeBusinessService } from '@allianz/ngx-pfe';
import { TalyFrameNavigationService } from '@allianz/taly-common/frame';

export const INSTRUCTIONS: Record<string, string> = {
  CONFIG_EXISTING_DEVICE_ADDMCBINSTALL_INSTRUCTION: $localize`CONFIG_EXISTING_DEVICE_ADDMCBINSTALL_INSTRUCTION`,
  CONFIG_EXISTING_DEVICE_ADDMETERINSTALL_INSTRUCTION: $localize`CONFIG_EXISTING_DEVICE_ADDMETERINSTALL_INSTRUCTION`,
  CONFIG_EXISTING_DEVICE_INCREASEMCBINSTALL_INSTRUCTION: $localize`CONFIG_EXISTING_DEVICE_INCREASEMCBINSTALL_INSTRUCTION`,
  CONFIG_EXISTING_DEVICE_MAINTENANCEDIRECTOR_INSTRUCTION: $localize`CONFIG_EXISTING_DEVICE_MAINTENANCEDIRECTOR_INSTRUCTION`,
  CONFIG_EXISTING_DEVICE_HOUSECONSTRUCTION_DIRECTOR_INSTRUCTION: $localize`CONFIG_EXISTING_DEVICE_HOUSECONSTRUCTION_DIRECTOR_INSTRUCTION`,
  CONFIG_NEW_DEVICE_ADDMCBINSTALL_INSTRUCTION: $localize`CONFIG_NEW_DEVICE_ADDMCBINSTALL_INSTRUCTION`,
  CONFIG_NEW_DEVICE_ADDMETERINSTALL_INSTRUCTION: $localize`CONFIG_NEW_DEVICE_ADDMETERINSTALL_INSTRUCTION`,
  CONFIG_NEW_DEVICE_INCREASEMCBINSTALL_INSTRUCTION: $localize`CONFIG_NEW_DEVICE_INCREASEMCBINSTALL_INSTRUCTION`,
  CONFIG_NEW_DEVICE_MAINTENANCEDIRECTOR_INSTRUCTION: $localize`CONFIG_NEW_DEVICE_MAINTENANCEDIRECTOR_INSTRUCTION`,
  CONFIG_NEW_DEVICE_HOUSECONSTRUCTIONDIRECTOR_INSTRUCTION: $localize`CONFIG_NEW_DEVICE_HOUSECONSTRUCTIONDIRECTOR_INSTRUCTION`
};

@UntilDestroy()
@Component({
  selector: 'bb-ec-equipment-configure',
  templateUrl: 'ec-equipment-configure.component.html',
  styleUrls: ['ec-equipment-configure.component.scss'],
  providers: [createBuildingBlockProvider(EcEquipmentConfigureComponent)]
})
export class EcEquipmentConfigureComponent
  extends AbstractBuildingBlock<EcEquipmentConfigureState, EcEquipmentConfigureResources>
  implements OnInit
{
  aclFormBinding?: FormBindingReturnValue;

  validationMap?: Map<string, ValidationConfig[]>;

  instruction = '';

  myResource!: EcEquipmentConfigureResources;

  constructor(
    injector: Injector,
    private cdr: ChangeDetectorRef,
    protected ecFacadeService: EcFacadeService,
    protected pfeBusinessService: PfeBusinessService,
    protected talyFrameNavigationService: TalyFrameNavigationService
  ) {
    super(injector);
    this.ecFacadeService.isCompletedState$.subscribe((data) => {
      if (data) {
        this.commitCompletion();
      } else {
        this.revertCompletion();
      }
    });

    this.ecFacadeService.startOverProcess$.subscribe((data) => {
      this.startOverProcess(data);
    });
  }

  override ngOnInit(): void {
    this.setupFormChangesSubscriptions();
    this.setupAclBinding();
  }

  private setupAclBinding(): void {}

  private setupFormChangesSubscriptions(): void {}

  private checkCompletion(): void {
    if (this.ecFacadeService.isCompleted()) {
      this.commitCompletion();
    } else {
      this.revertCompletion();
    }
  }

  private checkAutoSkipPage() {
    // Auto next
    const navigatingFrom = this.pfeBusinessService.getValueByExpression(
      '$._pfe.navigationState.navigatingFrom'
    );
    // Back
    if (navigatingFrom === 'config-new-device') {
      this.pfeBusinessService.navigateBack();
    } else {
      // Next
      this.pfeBusinessService.navigateNext(true);
    }
  }

  override setValidationConfiguration(data: ValidationConfigItem[]): void {
    this.cdr.markForCheck();
  }

  override setResources(data: EcEquipmentConfigureResources): void {
    console.log('The Building Block EcEquipmentConfigure received the following resources:', data);
    this.myResource = data;

    this.ecFacadeService.startOverProcess$.next(data);
  }

  private startOverProcess(data: EcEquipmentConfigureResources) {
    this.ecFacadeService.initialInstallation(data);
    this.ecFacadeService.initialForConfigExistingDevice();
    this.ecFacadeService.traversal.recursive();

    this.instruction =
      INSTRUCTIONS[`CONFIG_EXISTING_DEVICE_${this.ecFacadeService.director.name}_INSTRUCTION`];

    this.ecFacadeService.traversal.componentsState$.pipe(untilDestroyed(this)).subscribe(() => {
      this.stateChanged();
      this.checkCompletion();
    });
  }

  override setState(data: EcEquipmentConfigureState): void {}

  override getState(): EcEquipmentConfigureState {
    return {
      componentsState: this.ecFacadeService.traversal.componentsState$.value
    };
  }

  override onPageConnection(): void {
    console.log('The Building Block EcEquipmentConfigure got connected to the page');
    this.checkCompletion();
    if (this.ecFacadeService.isCompleted()) {
      this.checkAutoSkipPage();
    }
  }

  override onPageDisconnected() {
    console.log('onPageDisconnected');
    // this.ecFacadeService.saveExistingState();
  }

  testComplettionAllExisting() {
    this.ecFacadeService.director.testingCompleteAllExising();
    this.ecFacadeService.traversal.recursive();
  }

  test() {
    this.ecFacadeService.director.testing();
    this.ecFacadeService.traversal.recursive();
    // this.ecFacadeService.traversal.display();
    // console.log('Need to be configured');
    // console.log(this.ecFacadeService.traversal.getNeedToBeConfigured());
    // console.log(this.ecFacadeService.builder.root);
    // console.log(this.ecFacadeService.traversal.wires);

    // let chartData = {
    //   components: this.ecFacadeService.traversal.components,
    //   boxContainers: this.ecFacadeService.traversal.boxContainers,
    //   wires: this.ecFacadeService.traversal.wires
    // };
    // chartData = mainBuilder();
    // this.orgChartService.createNewChart();
    // this.orgChartService.reloadChart()
    // this.orgChartService.loadChart(chartData);
    //
    // console.log(chartData);
    // console.log(Object.keys(this.orgChartService.chart.nodes).length);
    // this.getSlinks(this.ecFacadeService.traversal.wires);
    // this.getSlinks(chartData.wires);
    // this.getClinks(chartData.boxContainers);
  }
}
