import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Injector,
  OnInit,
  ViewChild
} from '@angular/core';
import { map } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ClusterNode, Edge, Graph, Node } from '@swimlane/ngx-graph';
import {
  AbstractBuildingBlock,
  createBuildingBlockProvider,
  ValidationConfigItem
} from '@allianz/taly-core';
import { EcNgxGraphResources, EcNgxGraphState } from './ec-ngx-graph.model';
import { ComponentsState } from '@ec-workspace/ec-library';
import { EcNgxGraphService } from './ec-ngx-graph.service';
import { EquipmentFormComponent } from '../shared/components/equipment-form/equipment-form.component';
import { NxDialogService, NxModalRef } from '@aposin/ng-aquila/modal';
import { EcFacadeService } from '../shared/services/ec-facade.service';
import { Subject } from 'rxjs';
import * as shape from 'd3-shape';
import { DOCUMENT } from '@angular/common';
import SvgSaver from 'svgsaver';

@UntilDestroy()
@Component({
  selector: 'bb-ec-ngx-graph',
  templateUrl: 'ec-ngx-graph.component.html',
  styleUrls: ['ec-ngx-graph.component.scss'],
  providers: [createBuildingBlockProvider(EcNgxGraphComponent)]
})
export class EcNgxGraphComponent
  extends AbstractBuildingBlock<EcNgxGraphState, EcNgxGraphResources>
  implements OnInit, AfterViewInit
{
  public cluster: ClusterNode[] = [];
  public links: Edge[] = [];
  public nodes: any[] = [];
  public layoutSettings = {
    orientation: 'TB'
  };
  public curve: any = shape.curveCardinal;
  // public curve;
  public layout = 'dagreCluster';
  // public layout: Layout = new EcLayout();
  // public layout: Layout = new EcLayout();
  // public layout: Layout = new D3ForceDirectedLayout();
  view = [1024, 700];

  resourceBB!: any;

  graphModel!: Graph;
  zoomLevel = 1;
  private zoomStep = 0.25;

  center$: Subject<boolean> = new Subject();
  zoomToFit$: Subject<boolean> = new Subject();

  zoomToFitObs$ = this.zoomToFit$.asObservable().pipe(
    map(() => {
      this.center$.next(true);
      return true;
    })
  );

  componentDialogRef!: NxModalRef<EquipmentFormComponent>;

  @ViewChild('configGraphContainer', { static: true })
  private configGraphContainer!: ElementRef;

  private svgAttrWhitelist = ['startOffset'];

  // Used for saving image functionality
  private readonly IMAGE_MARGIN = 50;

  constructor(
    injector: Injector,
    private cdr: ChangeDetectorRef,
    private ecNgxGraphService: EcNgxGraphService,
    private ecFacadeService: EcFacadeService,
    public dialogService: NxDialogService,
    @Inject(DOCUMENT) private _document: any
  ) {
    super(injector);
    // Dirty little trick to access the internal whitelist:
    const svgSaver = new SvgSaver();
    this.svgAttrWhitelist = [...svgSaver.attrs, ...this.svgAttrWhitelist];
  }

  override ngOnInit(): void {
    this.ecNgxGraphService.graphModel$.subscribe((model) => {
      this.graphModel = model;

      // TODO: Dynamically calculate height of the graph up to a max height:
      // .getElementsByClassName('nodes')[0].getBBox()
      // .getElementsByClassName('links')[0].getBBox()
    });
  }

  ngAfterViewInit(): void {
    // Capture mousewheel events to ensure scrolling works:
    this.configGraphContainer.nativeElement.addEventListener(
      'mousewheel',
      (event: Event) => {
        event.stopPropagation();
        return true;
      },
      true
    );
  }

  private checkCompletion(): void {
    this.commitCompletion();
  }

  override setValidationConfiguration(data: ValidationConfigItem[]): void {
    this.cdr.markForCheck();
  }

  override setResources(data: EcNgxGraphResources): void {
    console.log('The Building Block EcNgxGraph received the following resources:', data);
    this.resourceBB = data;
    // if (data && data.componentsState) {
    //   this.ngxGraphAdaptor(data.componentsState);
    // }
    if (!data.componentsState) {
      return;
    }
    this.ecNgxGraphService.loadComponentsConfig(data.componentsState);
  }

  override setState(data: EcNgxGraphState): void {}

  override getState(): EcNgxGraphState {
    return {};
  }

  override onPageConnection(): void {
    console.log('The Building Block EcNgxGraph got connected to the page');
    this.checkCompletion();
  }

  onNodeClick($event: any) {
    console.log($event);
  }

  public getStyles(node: any): any {
    return {
      'background-color': node.data.backgroundColor
    };
  }

  ngxGraphAdaptor(state: ComponentsState) {}

  nodeSelected($event: Node) {
    console.log($event);
    if (!$event.data.canConfig) {
      return;
    }
    this.openEquipmentForm($event.id).subscribe(() => {});
  }

  openEquipmentForm(id: string) {
    this.componentDialogRef = this.dialogService.open(EquipmentFormComponent, {
      showCloseIcon: true,
      data: {
        id: id
      }
    });
    this.componentDialogRef.componentInstance.cancel.subscribe(() => {
      this.componentDialogRef.close();
    });
    this.componentDialogRef.componentInstance.save.subscribe((result) => {
      this.componentDialogRef.close(result);
    });

    return this.componentDialogRef.afterClosed();
  }

  zoomOut() {
    this.zoomLevel -= this.zoomStep;
  }

  zoomIn() {
    this.zoomLevel += this.zoomStep;
  }

  resetZoom() {
    this.zoomLevel = 1;
  }

  zoomChange($event: number) {
    this.zoomLevel = $event;
  }

  clear() {
    const clear = confirm('Really clear the config?');
    // if (clear) {
    //   this.editorService.updateConfig(undefined, true);
    // }
  }

  linkClick($event: any) {
    console.log('linkClick', $event);
    this.openEquipmentForm($event.id);
  }

  saveImage(asPNG?: boolean) {
    const svgSaver = new SvgSaver({ attrs: this.svgAttrWhitelist });
    const svgDOM = this._document.getElementsByClassName('ngx-charts')[0];

    const svg = svgSaver.cloneSVG(svgDOM);
    // Remove the panning-rec as it breaks the exported svg.
    const panningRec = svg.getElementsByClassName('panning-rect')[0];
    panningRec.parentNode.removeChild(panningRec);
    // Remove the transform as it cuts of the images, if the graph was moved.
    const graph = svg.getElementsByClassName('graph')[0];
    graph.removeAttribute('transform');

    // Determine the real size of the svg:
    // This adds an invisible clone of the svg to the dom and measures its size
    const tmpDiv = this._document.createElement('div');
    tmpDiv.setAttribute('style', 'position:absolute; visibility:hidden; width:0; height:0');
    const tmpSVG = svgSaver.cloneSVG(svg);
    tmpSVG.style.visibility = 'hidden';
    tmpDiv.appendChild(tmpSVG);
    this._document.body.appendChild(tmpDiv);

    // It seems like the size calculation is sometimes wrong, wait a bit for the browser.
    setTimeout(() => {
      const bBox = tmpDiv.firstElementChild.getBBox();
      this._document.body.removeChild(tmpDiv);

      // Set correct size:
      svg.setAttribute('width', bBox.width + this.IMAGE_MARGIN);
      svg.setAttribute('height', bBox.height + this.IMAGE_MARGIN);

      if (!asPNG) {
        svgSaver.asSvg(svg, 'navigation.svg');
      } else {
        svgSaver.asPng(svg, 'navigation.png');
      }
    }, 50);
  }
}
